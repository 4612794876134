

import { url } from "./url";


export function getExportOrdersPressUrl(){
	return url+"mall/export/orders-express/info"
}
export function getExportOrdersProductApi(){
	return url+"mall/export/order-product"
}