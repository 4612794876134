<template>
  <div >
	
	    <el-form ref="userPasswordForm" :model="formData" 
	       center label-width="120px" style="margin: 50px auto">
	      <el-form-item label="原密码：" required >
	        <el-input size="large" v-model="formData.old_password" clearable style="width: 360px"></el-input>
	      </el-form-item>
	      <el-form-item required  label="新密码：">
	        <el-input size="large" type="password" v-model="formData.new_password" clearable
	          style="width: 360px"></el-input>
	      </el-form-item>
	      <el-form-item required  label="确认密码：">
	        <el-input size="large" type="password" v-model="formData.new_password_2" clearable
	          style="width: 360px"></el-input>
	      </el-form-item>
	      <el-form-item>
	        <el-button type="primary" @click="settingPassword" v-show="hasPermission(this.$route.name, 'Changepassword')">
	          <i class="fa fa-check"></i>提交
	        </el-button>
	        <el-button @click="resetForm('passwordForm')" type="info"
	          v-show="hasPermission(this.$route.name, 'Changepassword')">
	          <i class="fa fa-reply-all"></i>重置
	        </el-button>
	      </el-form-item>
	    </el-form>
	
  </div>
    

</template>

<script>

import {updateAdminOwnPwsApi } from '@/api/system'
import { useMutitabsStore } from "@/store/mutitabs";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
  name: "personalCenter",
  components: {
    LyUploadAvatar,
  },
  setup() {
    const mutitabsstore = useMutitabsStore()
    return { mutitabsstore }
  },
  data() {
	  
	  return{
		  formData:{
			  'old_password':"",
			  "new_password":"",
			  "new_password_2":"",
		  }
	  }
    // var validatePass = (rule, value, callback) => {
    //   const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
    //   if (value === '') {
    //     callback(new Error('请输入密码'))
    //   } else if (value === this.userPasswordInfo.oldPassword) {
    //     callback(new Error('原密码与新密码一致'))
    //   }
    //   // else if (!pwdRegex.test(value)) {
    //   //   callback(new Error('您的密码复杂度太低(密码中必须包含字母、数字)'))
    //   // }
    //   else {
    //     if (this.userPasswordInfo.newPassword2 !== '') {
    //       this.$refs.userPasswordForm.validateField('newPassword2')
    //     }
    //     callback()
    //   }
    // }
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请再次输入密码'))
    //   } else if (value !== this.userPasswordInfo.newPassword) {
    //     callback(new Error('两次输入密码不一致!'))
    //   } else {
    //     callback()
    //   }
    // }
    // return {
    //   position: 'right',
    //   activeName: 'passwrod',
    //   userInfo: {
    //     name: '',
    //     gender: 1,
    //     mobile: '',
    //     avatar: '',
    //     email: '',
    //     identity: 1,
    //   },
    //   userInforules: {
    //     // avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
    //     name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
    //     mobile: [
    //       { required: true, pattern: /^1[3|4|5|7|8|6]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
    //     ],
    //     bank_name: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
    //     bank_no: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
    //   },
    //   userPasswordInfo: {
    //     oldPassword: '',
    //     newPassword: '',
    //     newPassword2: ''
    //   },
    //   passwordRules: {
    //     oldPassword: [
    //       {
    //         required: true,
    //         message: '请输入原密码',
    //         trigger: 'blur'
    //       }
    //     ],
    //     newPassword: [{ validator: validatePass, trigger: 'blur' }],
    //     newPassword2: [{ validator: validatePass2, trigger: 'blur' }]
    //   }
    // }
  },
  mounted() {
  
  },
  methods: {

  

    // 重置
    resetForm(name) {
      const _self = this
      if (name === 'info') {
        _self.getCurrentUserInfo()
      } else {
        _self.userPasswordInfo = {
          oldPassword: '',
          newPassword: '',
          newPassword2: ''
        }
      }
    },
    // tab切换,默认切换清除原字符
    handleClick(tab, event) {
      const _self = this
      // if (tab.paneName === 'userInfo') {
      //   _self.$refs.userPasswordForm.resetFields()
      // } else {
      //   _self.$refs.userInfoForm.resetFields()
      // }
    },
    /**
     * 重新设置密码
     */
    settingPassword() {
      const that = this
      if(this.formData.new_password!=this.formData.new_password_2){
		   this.$message.warning("两次密码不一样")
		  return
	  }
	  if(!this.formData.new_password){
		   this.$message.warning("请输入新密码")
		  return
	  } 
	  if(!this.formData.new_password_2){
		   this.$message.warning("请输入确认密码")
		  return
	  }
      updateAdminOwnPwsApi({"old_password":this.formData.old_password,"new_password":this.formData.new_password,"new_password_2":this.formData.new_password_2}).then(res => {
        if (res.code == 2000) {
          // _self.activeName = 'userInfo'
          this.$message.success(res.msg)
        } else {
          this.$message.warning(res.msg)
        }
      })
    }

  }
}
</script>

<style scoped>
.ly-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  font-size: 17px;
  padding-bottom: 15px;
  color: #3c4a54;
  font-weight: bold;
}

.el-tabs .el-tabs__content {
  background-color: var(--el-bg-color) !important;
}

.el-tabs {
  background-color: var(--el-bg-color) !important;
}

::v-deep(.el-tabs__header) {
  margin-top: 20px !important;
}
</style>
