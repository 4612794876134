<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="商家名称：">
                                <el-input style="width:100%" v-model.trim="formInline.shop_name" maxlength="60" clearable
                                    placeholder="商家名称" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="货位号：">
                                <el-input style="width:100%" v-model.trim="formInline.goods_no" maxlength="60" clearable
                                    placeholder="货位号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单编号：">
                                <el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
                                    placeholder="订单编号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="入位人：">
                                <el-input style="width:100%" v-model.trim="formInline.ru_wei_name" maxlength="60"
                                    clearable placeholder="入位人" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="入位状态：">
                                <el-select style="width:100%" v-model="formInline.ru_wei_status" placeholder="请选择" clearable
                                    @change="search">
                                    <el-option v-for="item in statusList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="入位类型：">
                                <el-select style="width:100%" v-model="formInline.ru_wei_type" placeholder="请选择" clearable
                                    @change="search">
                                    <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单时间：">
                                <el-date-picker style="width:100%" v-model="timers" type="datetimerange"
                                    @change="timeChange" range-separator="至" start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
						<el-col :span="24">
							<el-form-item label="归属日期：">
								<el-date-picker style="width:19%" v-model="formInline.order_date" type="date"
									@change="selectDate" placeholder="订单归属日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="商品来源：">
								<el-select style="width:100%" v-model="formInline.product_from" placeholder="请选择" clearable
								    @change="search">
								    <el-option v-for="item in product_type_list" :key="item.id" :label="item.name" :value="item.id">
								    </el-option>
								</el-select>
							</el-form-item>
						</el-col>
                        <el-col :span="24">
                            <el-form-item label="">
                                <el-radio-group v-model="formInline.select_date"  style="margin-right: 12px;" @change="search">
                                    <el-radio-button  label="today">今日订单</el-radio-button>
                                    <el-radio-button  label="tomorrow">明日订单</el-radio-button>
                                </el-radio-group>
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                                <el-button  type="primary" icon="Receiving" @click="exportOrder()" >导出订单商品</el-button>
                                <el-button  type="primary" icon="Receiving" @click="pushPosNo()" >推送货位到珍情</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage"
                @selection-change="handleSelectionChange">
                <el-table-column width="100" prop="id" label="序号" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="shop_name" align="center" label="商家名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="90" prop="goods_no" align="center" label="货位号"></el-table-column>
                <el-table-column width="90" prop="customer_name" align="center" label="收货人" show-overflow-tooltip></el-table-column>
                <el-table-column width="90" prop="agent_name" align="center" label="代理商" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="order_id" align="center" label="订单编号" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="product_name" align="center" label="商品名称" show-overflow-tooltip></el-table-column>
                <el-table-column width="140" prop="order_date"  align="center" color:red  label="归属日期" ></el-table-column>
                <el-table-column width="100" prop="product_count" align="center" label="商品数量" ></el-table-column>
                <el-table-column width="105" prop="ru_wei_count" align="center" label="入位数量">
                    <template #default="scope">
                        <el-input-number v-model="scope.row.product_count" placeholder="请输入入位数量" :controls="false" :min="0"
                            :max="scope.row.count" style="width:100%" v-if="scope.row.ru_wei_status == 1"></el-input-number>
                        <span v-else>{{ scope.row.ru_wei_count }}</span>
                    </template>
                </el-table-column>
                <el-table-column width="110" prop="ru_wei_status_name" align="center" label="入位状态"></el-table-column>
               
                <el-table-column width="200" prop="ru_wei_date" align="center" label="入位时间"></el-table-column>
				 <el-table-column width="100" prop="product_from" align="center" label="商品来源"></el-table-column>
				 <el-table-column width="100"  align="center" label="珍情确认">
					  <template #default="scope">
						  <el-tag v-if="scope.row.zq_status==1">已确认</el-tag>
						  <el-tag v-if="scope.row.zq_status==0 && scope.row.product_from=='珍情'">待确认</el-tag>
						  <el-tag v-if="scope.row.zq_status==2">已撤消需补单</el-tag>
					  </template> 
				 </el-table-column> 
				 <el-table-column width="100"  align="center" label="货位推送">
					  <template #default="scope">
						  <el-tag v-if="scope.row.is_push_zq_pn">已推送</el-tag>
						  <el-tag v-if="!scope.row.is_push_zq_pn">未推</el-tag>
						  
					  </template> 
				 </el-table-column>
				 <el-table-column width="100" prop="zq_checkin_status_name" align="center" label="珍情入位状态"></el-table-column>
				
                <el-table-column width="120" prop="ru_wei_admin_name" align="center" label="入位人"></el-table-column>
                <el-table-column width="100" prop="ru_wei_type_name" align="center" label="入位类型"></el-table-column>
                <!-- <el-table-column min-width="150" prop="ru_wei_reason" label="原因"></el-table-column> -->
                <el-table-column label="操作" fixed="right" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <el-popconfirm title="确定要入位该商品吗？" @confirm="ruWeiAction(scope.row)" width="190px">
                            <template #reference>
                                <span class="table-operate-btn"
                                    v-show="hasPermission(this.$route.name, 'Ruwei') && scope.row.ru_wei_status == 1">正常入位</span>
                            </template>
                        </el-popconfirm>
                        <span class="table-operate-btn" style="color: gray;" v-if="scope.row.ru_wei_status == 2">已入位</span>
						<el-button type="warning" size="small" @click="updateRuWei(scope.row)">修改入位</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addModule ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addModule>
		<updateRuWeiDialog ref="updateRWDialogRef" v-if="show_update_ruwei_dialog" @updateData="getData"  @closed="show_update_ruwei_dialog=false"></updateRuWeiDialog>
    </div>
</template>
<script>
import addModule from "./components/addModuleInsertion";
import updateRuWeiDialog from "./components/updateRuWei";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallOrdergoods, fmallOrdergoodsDelete, fmallOrdergoodsRuwei,loadOrdersProductListApi ,updateRuWeiApi,handlePushPosNodApi} from '@/api/api'
import {getExportOrdersProductApi} from '@/api/export'
import {setStorage,getStorage} from '@/utils/util'
import axios from "axios";

export default {
    components: {
        Pagination,
        addModule,
		updateRuWeiDialog
    },
    name: 'InsertionManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
				select_date:"today",
                page: 1,
                limit: 10,
				product_from:0,
                
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 2, name: '已入位' },
                { id: 1, name: '待入位' }
            ],
            typeList: [
                { id: 1, name: '正常入位' },
                { id: 2, name: '缺货入位' },
            ],
			product_type_list:[ { id: 0, name: '全部' },{ id: 1, name: '集花宝' },{ id: 2, name: '珍情' }],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			show_update_ruwei_dialog:false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //多选项框被选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addModuleFlag.handleOpen(null, "新增")
            })
        },
		ruWeiAction(row){
			this.loadingSave = true
			updateRuWeiApi({id:row.id,
               reason_id:3}).then(res => {
			    this.loadingSave = false
			    if (res.code == 2000) {
			        this.$message.success(res.msg)
			        this.dialogVisible = false;
			        this.getData()
			    } else {
			        this.$message.warning(res.msg)
			    }
			})
		},
		
        handleRuwei(param) {
			
            // fmallOrdergoodsRuwei(param).then(res => {
            //     this.loadingSave = false
            //     if (res.code == 2000) {
            //         this.$message.success(res.msg)
            //         this.getData()
            //     } else {
            //         this.$message.warning(res.msg)
            //     }
            // })
        },
		select_date(e){
			console.log("select:",e)
			console.log("select:",this.formInline.select_date)
		},
        handleEdit(row, flag) {
            if (flag == 'ruwei') {
                if (row.ru_wei_count < row.count) {
                    this.isDialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.addModuleFlag.handleOpen(row, "缺货入位")
                    })
                } else {
                    let params = {
                        id: row.id,
                        ru_wei_count: row.ruwei_count,
                    }
                    this.handleRuwei(params)
                }

            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的数据吗？', {
                    closeOnClickModal: false
                }).then(res => {
                    fmallOrdergoodsDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
			if(this.formInline.select_date=="today"){
				this.getNowDate()
			}
			if(this.formInline.select_date=="tomorrow"){
				this.getNextDate()
			}
			 this.tableData=[]
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
			
            loadOrdersProductListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    let tempdata = res.data.data
                    tempdata.forEach(item => {
                        if (item.ruwei_status == 1) {
                            item.ruwei_count = item.count
                        }
                    })
					
                    this.tableData = tempdata
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.start_time = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.end_time = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
				this.formInline.select_date=""
				this.formInline.order_date=""
            } else {
                this.formInline.start_time = null
                this.formInline.end_time = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		updateRuWei(item){
			this.show_update_ruwei_dialog=true
			 this.$nextTick(() => {
			   this.$refs.updateRWDialogRef.handleOpen(item)
			   }
			   )
		},
		selectDate(e) {
			let timestamp = e.getTime()
			const dateObj = new Date(timestamp); // 创建Date对象
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			this.formInline.order_date = year + "-" + month + "-" + day
			this.formInline.select_date=""
			
		},
		getNextDate(){
			let dateObj = new Date();
			
			let hour=dateObj.getHours()
			let minutes=dateObj.getMinutes()
			
			
			if(hour>17){
				dateObj.setDate(dateObj.getDate()+1)
			}
			
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			
			this.formInline.order_date = year + "-" + month + "-" + day
		},
		getNowDate() {
			let dateObj = new Date();
			
			let hour=dateObj.getHours()
			let minutes=dateObj.getMinutes()
			
			
			if(hour<17){
				dateObj.setDate(dateObj.getDate()-1)
			}
			
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			
			this.formInline.order_date = year + "-" + month + "-" + day
		
			
		},
		async exportOrder(){
			 // console.log("url:",getExportOrdersPressUrl())
			 let token=getStorage('logintoken')
			const response=await axios({
				url:getExportOrdersProductApi(),
				headers:{
				    Authorization: 'JWT ' + token,
				},
				method:"GET",
				params:{order_date:this.formInline.order_date,product_from:this.formInline.product_from},
				responseType:'blob',
			})
			
			const url=window.URL.createObjectURL(new Blob([response.data]));
			const link=document.createElement('a');
			link.href=url;
			link.setAttribute('download',this.formInline.order_date+"_订单商品信息.xls")
			document.body.append(link);
			link.click()
			this.$message("请检查下载进度")
			// exprotOrderExpressApi({order_date:this.formInline.order_date}).then(res=>{
				
			// })
		},
		pushPosNo(){
			let vm = this
			vm.$confirm('确定推送珍情订单的货位吗？', {
			    closeOnClickModal: false
			}).then(res => {
			    handlePushPosNodApi().then(res => {
			        if (res.code == 2000) {
			            vm.$message.success(res.msg)
			            vm.search()
			        } else {
			            vm.$message.warning(res.msg)
			        }
			    })
			}).catch(() => {
			
			})
		}

    },
    created() {
		this.getNowDate()
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
