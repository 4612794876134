<template>
	<div>
		<ly-dialog v-model="dialogVisible" :title="loadingTitle" width="90%" top="10px" @closed="handleClose"
			:fullscreen="false" :draggable="false">
			<el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
				label-width="auto">
				<div class="ly-title">
					订单信息
				</div>
				<el-row :gutter="20" style=" height: 30px;">
					<el-col :span="5">
						<el-form-item label="订单来源：">
							<p style="color: red;">{{ parent_order_info.order_from }}</p>
						</el-form-item>
					</el-col><el-col :span="5">
						<el-form-item label="订单编号：">
							{{ parent_order_info.order_id }}
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="支付时间：">
							{{ parent_order_info.pay_time }}
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="支付单号：">
							{{ parent_order_info.pay_order_no }}
						</el-form-item>
					</el-col>
			    </el-row>
				<el-row :gutter="20" style=" height: 30px;">
					<el-col :span="5">
						<el-form-item label="订单商品总数：">
							{{ parent_order_info.orders_product_count }}
						</el-form-item>
					</el-col><el-col :span="5">
						<el-form-item label="发货商品总数：">
							{{ parent_order_info.real_product_count }}
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20" style=" height: 30px;">
					<el-col :span="5">
						<el-form-item label="订单商品总金额：">
							{{ parent_order_info.total_product_money }}
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="优惠总金额：">
							{{parent_order_info.discount_value }}
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="运费：">
							{{ parent_order_info.total_pay_postage }}
						</el-form-item>
					</el-col>
					<el-col :span="5">
						<el-form-item label="实付金额：">
							{{ parent_order_info.real_pay_money }}
						</el-form-item>
					</el-col>
					
				</el-row>
				
				<div class="ly-title" style="margin-top: 10px;">
					申请售后商品信息
					
				</div>
				<el-table border :data="formData.product_list">
					<el-table-column width="80" prop="sale_type_name" label="类型"></el-table-column>
					<el-table-column min-width="120" prop="product_name" label="商品名称"
						show-overflow-tooltip>
						<template #default="scope">
						{{scope.row.product_name}} {{scope.row.level}}
						
						</template>
						</el-table-column>
					<el-table-column width="100" prop="level" label="商家" show-overflow-tooltip>
						<template #default="scope">{{scope.row.shop_name}} ({{scope.row.shop_code}})
						
						</template>
					</el-table-column>
					<el-table-column width="100" prop="level" label="商品来源" show-overflow-tooltip>
						<template #default="scope" >
						<p style="color: red;">{{scope.row.order_from}}</p> 
						
						</template>
					</el-table-column>
					<el-table-column width="80" prop="product_cover" label="图片">
						<template #default="scope">
							<el-image :src="scope.row.product_cover" style="width: 30px;height: 30px"
								:preview-src-list="[scope.row.product_cover]" :preview-teleported="true"></el-image>
						</template>
					</el-table-column>
					<el-table-column min-width="100" prop="" label="商品规格">
						<template #default="scope">{{scope.row.spec_value+scope.row.spec_unit}}
						</template>
					</el-table-column>
					
					<el-table-column width="90" prop="order_count" label="下单数量"></el-table-column>
					<el-table-column width="90" prop="count" label="发货数量"></el-table-column>
					
					<el-table-column min-width="110" prop="shop_price" label="成本价">
						<template #default="scope">
							{{ parseFloat(scope.row.shop_price).toFixed(2) }}
						</template>
					</el-table-column>
					<el-table-column min-width="110"  label="平台加价">
						<template #default="scope">
							{{ parseFloat(scope.row.add_price).toFixed(2)}}
						</template>
					</el-table-column>
					<el-table-column min-width="110"  label="区域加价">
						<template #default="scope">
							{{ parseFloat(scope.row.area_add_price).toFixed(2)}}
						</template>
					</el-table-column>
					<el-table-column min-width="110" prop="price" label="支付价格">
						<template #default="scope">
							{{ parseFloat(scope.row.shop_price+scope.row.add_price+scope.row.area_add_price).toFixed(2) }}
						</template>
					</el-table-column>
					<el-table-column width="100" prop="discount_value" label="优惠金额"></el-table-column>
					<el-table-column width="120" prop="max_refund" label="最大退款(总价)"></el-table-column>
					<el-table-column width="100" prop="spec_weight" label="商品重量"></el-table-column>
					<el-table-column min-width="110" prop="total_weight" label="总重量">

					</el-table-column>
					<!-- <el-table-column min-width="110" prop="total_product_money" label="总价"></el-table-column> -->
				</el-table>
				<div class="ly-title" style="margin-top: 10px;">
					收货信息
				</div>
				<div>
					<el-row :gutter="20" style=" height: 30px;">
						<el-col :span="3">
							<el-form-item label="收货人：">
								{{ parent_order_info.customer_name }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="电话：">
								{{ parent_order_info.customer_phone }}
							</el-form-item>
						</el-col>
						<el-col :span="9">
							<el-form-item label="收货地址：">
								{{ parent_order_info.customer_address }}
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="收货时间：">
								{{ parent_order_info.customer_sign_time }}
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="20" style=" height: 30px;">
						<el-col :span="3">
							<el-form-item label="代理商：">
								{{ parent_order_info.customer_name }}
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="物流：">
								{{ parent_order_info.express_name }}
							</el-form-item>
						</el-col>
						<el-col :span="9">
							<el-form-item label="物流单号：">
								{{ parent_order_info.express_no }}
							</el-form-item>
						</el-col>
						
					</el-row>
				</div>
				
				<div class="ly-title" style="margin-top: 10px;">
					申请信息
				</div>
				<el-row :gutter="20">
					<el-col :span="6">
						<el-form-item label="申请编号：">
							{{ apply_info.apply_no }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="联系电话：">
							{{ apply_info.phone }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="申请原因：">
							{{ apply_info.reason }}
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="申请数量：">
							<p style="color: red;">{{ apply_info.apply_count }}</p>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="描述：">
							<el-text>
								{{ apply_info.desc }}
							</el-text>
							
						</el-form-item>
					</el-col>
						</el-row>
				<el-row :gutter="20">
					
						<el-col :span="5">
							<el-form-item label="全景照片：">
								<el-image  preview-teleported :src="apply_info.panorama_img"
								:preview-src-list="[apply_info.panorama_img]"
									style="width: 150px;height: 150px;margin-right: 10px;"></el-image>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="数量照片：">
								<el-image  preview-teleported :src="apply_info.quantity_img"
									:preview-src-list="[apply_info.quantity_img]"
								style="width: 150px;height: 150px;margin-right: 10px;"></el-image>
							</el-form-item>
						</el-col>
						<el-col :span="5">
							<el-form-item label="特写照片：">
								<el-image  preview-teleported :src="apply_info.problem_img"
									 :preview-src-list="[apply_info.problem_img]"
									style="width: 150px;height: 150px;margin-right: 10px;"></el-image>
							</el-form-item>
						</el-col>
						<!-- <el-form-item label="照片：">
							<el-image v-for="item in formData.apply_imgs" preview-teleported :src="item"
								:preview-src-list="[item]" v-bind:key="item.id"
								style="width: 100px;height: 100px;margin-right: 10px;"></el-image>
						</el-form-item> -->
					
					<!-- <el-col :span="24">
						<el-form-item label="照片：">
							<el-image v-for="item in formData.apply_imgs" preview-teleported :src="item"
								:preview-src-list="[item]" v-bind:key="item.id"
								style="width: 100px;height: 100px;margin-right: 10px;"></el-image>
						</el-form-item>
					</el-col> -->
					
				</el-row>
				<el-row :gutter="20">
					<el-col :span="24">
						<el-form-item label="补充照片：">
							<el-image v-for="item in apply_info.img_list" preview-teleported :src="item"
								:preview-src-list="[item]" v-bind:key="item.id"
								style="width: 150px;height: 150px;margin-right: 10px;"></el-image>
						</el-form-item>
					</el-col>
					</el-row>
				
				<div class="ly-title" style="margin-top: 10px;">
					审核信息
				</div>
				<el-row :gutter="20">
					<div v-if="formData.audit_status == 10">
						<el-form-item label="售后状态：">
							{{ formData.audit_status_name }}
						</el-form-item>
						<el-form-item label="审核：">
							<el-radio-group v-model="auditData.action">
								<el-radio :value="1">通过</el-radio>
								<el-radio :value="2">未通过</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="处理结果：" class="is-required">
							<el-input v-model="formData.audit_remarks" style="width: 100%;"
								:autosize="{ minRows: 2, maxRows: 4 }" type="textarea"></el-input>
						</el-form-item>
						<el-form-item label="商家退款：">
							<el-input v-model="formData.shop_refund" style="width: 100%;" :disabled="false"
								@blur="addRefund()"></el-input>

						</el-form-item>
						<el-form-item label="平台退款：">
							<el-input v-model="formData.platform_refund" style="width: 100%;" :disabled="false"
								@blur="addRefund()"></el-input>

						</el-form-item>
						<el-form-item label="退款总额：">
							{{ formData.total_refund }}
						</el-form-item>
					</div>
					<div v-if="formData.audit_status > 10">
						<el-form-item label="售后状态：">
							{{ formData.audit_status_name }}
						</el-form-item>

						<el-form-item label="处理结果：" class="is-required">
							<el-input v-model="formData.audit_remarks" style="width: 100%;"
								:autosize="{ minRows: 2, maxRows: 4 }" type="textarea" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="商家退款：">
							<el-input v-model="formData.shop_refund" style="width: 100%;" :disabled="true"></el-input>

						</el-form-item>
						<el-form-item label="平台退款：">
							<el-input v-model="formData.platform_refund" style="width: 100%;"
								:disabled="true"></el-input>

						</el-form-item>
						<el-form-item label="退款总额：">
							{{ formData.total_refund }}
						</el-form-item>
					</div>

					<!-- <el-col :span="24">
						<el-form-item label="审核：" v-if="formData.audit_status == 10 && is_audit">
							<el-radio-group v-model="auditData.action">
								<el-radio :label="1">通过</el-radio>
								<el-radio :label="2">未通过</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="售后状态：" v-else>
							{{ formData.audit_status_name }}
						</el-form-item>
					</el-col>
					<el-col :span="24" v-if="formData.audit_status == 10 && is_audit && auditData.action == 2">
						<el-form-item label="原因：" class="is-required">
							<el-input v-model="formData.audit_remarks" style="width: 100%;"
								:autosize="{ minRows: 2, maxRows: 4 }" type="textarea"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="24" v-if="formData.audit_status == 20 || formData.audit_status == 30">
						<el-form-item label="原因：">
							<span>{{
                                formData.audit_remarks }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="24" v-if="!(!is_audit && formData.audit_status == 10)">
						<el-form-item label="商家退款：">
							<el-input v-model="formData.shop_amount" style="width: 100%;" :disabled="true"
								v-if="formData.audit_status == 10 && is_audit"></el-input>
							<span v-else>{{ formData.shop_amount }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="24" v-if="!(!is_audit && formData.audit_status == 10)">
						<el-form-item label="平台退款：">
							<el-input v-model="formData.platform_amount" style="width: 100%;" :disabled="true"
								v-if="formData.audit_status == 10 && is_audit"></el-input>
							<span v-else>{{ formData.platform_amount }}</span>
						</el-form-item>
					</el-col>
					<el-col :span="6" v-if="formData.audit_status != 10">
						<el-form-item label="退款总额：">
							{{ formData.total_amount }}
						</el-form-item>
					</el-col> -->
				</el-row>
			</el-form>
			<template #footer>
				<el-button @click="handleClose" :loading="loadingSave">取消</el-button>
				<el-button type="primary" @click="submitData" :loading="loadingSave"
					v-if="formData.audit_status == 10 ">提交审核</el-button>
			</template>
		</ly-dialog>
	</div>
</template>

<script>
	import {
		fmallOrderaftersalesDetail,
		fmallOrderaftersalesAudit,
		laodAdterSalesDetailApi,
		auditAfterSalesApplyApi
	} from "@/api/api";
	import LyDialog from "@/components/dialog/dialog";
	import LyUploadAvatar from "@/components/upload/avatar";
	import {
		deepClone
	} from "@/utils/util"
	export default {
		name: "addModuleMallOrderServiceDetail",
		components: {
			LyDialog,
			LyUploadAvatar
		},
		emits: ['refreshData', 'closed'],
		data() {
			return {
				dialogVisible: false,
				loadingSave: false,
				loadingTitle: '',
				defaultImg: require('../../../assets/img/avatar.jpg'),
				id: "",
				is_audit: false,
				formData: {
					imgs: [],
					audit_status: 10,
					goods_info: [],
					platform_refund: 0,
					shop_refund: 0,
					total_refund: 0
				},
				parent_order_info:{},
				apply_info:{},
				tableData: [],
				rules: {
					// username: [
					//     { required: true, message: '请输入用户名', trigger: 'blur' }
					// ],
				},
				auditData: {
					id: '',
					action: 1,
				}
			}
		},
		methods: {
			handleClose() {
				this.dialogVisible=false
				this.$emit('closed')
			},
			handleOpen(item, flag, is_audit) {
				this.loadingTitle = flag
				this.dialogVisible = true
				if (item) {
					this.id = item.id

					this.auditData.id = item.id
					this.getOderDetail(this.id)
				}
				this.is_audit = is_audit
			},
			addRefund() {
				let shop_refund=0
				let platform_refund=0
				if (this.formData.shop_refund){
					shop_refund=parseFloat(this.formData.shop_refund)
				}
				if(this.formData.platform_refund){
					platform_refund=parseFloat(this.formData.platform_refund)
				}
				
				this.formData.total_refund = shop_refund+platform_refund
			},
			getOderDetail(id) {
				laodAdterSalesDetailApi({
					id: id
				}).then(res => {
					if (res.code === 2000) {
						
						this.formData = res.data
						this.parent_order_info=this.formData.parent_order_info
						this.apply_info=res.data.apply_info
						
					}
				})
			},
			submitData() {
				var that=this
				let msg=""
				
				if (this.auditData.action==1){
					msg="确定同意售后申请并退款吗？商家退款："+this.formData.shop_refund+"元,平台退款："+this.formData.platform_refund+"元"
				}
				if(this.auditData.action==2){
					msg="确定拒绝该售后申请吗？"
				}
				
				this.$confirm(msg, "警告", {
				    confirmButtonText: "确定",
				    cancelButtonText: "取消",
				    type: "warning"
				}).then(function() {
					   that.loadingSave =true
					   that.$refs['rulesForm'].validate(obj => {
					   	if (obj) {
					   		
					   		let param = {
					   			...that.auditData
					   		}
					   		param.audit_remarks = that.formData.audit_remarks
					   		if (!param.audit_remarks && that.auditData.action == 2) {
					   			that.$message.warning("请填写拒绝原因")
					   			return
					   		}
					   		param.shop_refund=that.formData.shop_refund
					   		param.platform_refund=that.formData.platform_refund
					   		
					   		auditAfterSalesApplyApi(param).then(res => {
					   			that.loadingSave = false
					   			if (res.code == 2000) {
					   				that.$message.success(res.msg)
					   				that.dialogVisible = false;
					   				that.handleClose()
					   				that.$emit('refreshData')
					   			} else {
					   				that.$message.warning(res.msg)
					   			}
					   		})
					   	}
					   })
					})
				
				
			},
		}
	}
</script>
<style scoped>
	.ly-title {
		border-bottom: 1px solid #eee;
		margin-bottom: 20px;
		font-size: 17px;
		padding-bottom: 15px;
		color: #3c4a54;
		font-weight: bold;
	}
</style>