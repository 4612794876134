<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
				<el-form-item label="归属超级代理：" >
					<el-select v-model="formInline.appid" placeholder="请选择"  style="width: 220px;" filterable >
					    <el-option
					      v-for="item in super_agent_list"
					      :key="item.id"
					      :label="item.name"
					      :value="item.appid">
					    </el-option>
					  </el-select>
				</el-form-item>
				
                <el-form-item label="名称：">
                    <el-input size="default" v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称"
                        @change="search" style="width:200px"></el-input>
                </el-form-item>
                <!--                <el-form-item label="创建时间：">-->
                <!--                    <el-date-picker-->
                <!--                            style="width:100% !important;"-->
                <!--                            v-model="timers"-->
                <!--                            size="small"-->
                <!--                            type="datetimerange"-->
                <!--                            @change="timeChange"-->
                <!--                            range-separator="至"-->
                <!--                            start-placeholder="开始日期"-->
                <!--                            end-placeholder="结束日期">-->
                <!--                    </el-date-picker>-->
                <!--                </el-form-item>-->
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button size="default" icon="Plus" @click="addModule" type="primary"
                        v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
            </el-form>
        </div>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <!-- <el-table-column type="index" width="60" align="center" label="序号">
                <template #default="scope">
                    <span v-text="getIndex(scope.$index)"></span>
                </template>
            </el-table-column> -->
            <el-table-column min-width="150" prop="name" label="客服姓名"></el-table-column>
            <el-table-column min-width="150" prop="mobile" label="手机号"></el-table-column>
            <el-table-column min-width="150" prop="weixin" label="微信号"></el-table-column>
            <el-table-column min-width="150" prop="super_agent_name" label="归属超代"></el-table-column>
			<el-table-column min-width="100" label="状态">
			  <template #default="scope">
			    <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
			               @change="changeStatus(scope.row)">
			    </el-switch>
			  </template>
			</el-table-column>
            <!-- <el-table-column min-width="180" prop="age" show-overflow-tooltip label="内容">
                <template #default="scope">
                    <el-tag>{{scope.row.agent_name}}</el-tag>
               </template>
            </el-table-column> -->
			<!-- <el-table-column min-width="180" prop="desc" show-overflow-tooltip label="内容">
                <template #default="scope">
                    <div v-html="customEllipsis(scope.row.desc)" class="ellipsis"></div>
               </template>
            </el-table-column> -->
            <el-table-column min-width="180" prop="create_datetime" label="创建时间"></el-table-column>
            <el-table-column label="操作" fixed="right" width="180">
                <template #header>
                    <div style="display: flex;justify-content: space-between;align-items: center;">
                        <div>操作</div>
                        <div @click="setFull">
                            <el-tooltip content="全屏" placement="bottom">
                                <el-icon><full-screen /></el-icon>
                            </el-tooltip>
                        </div>
                    </div>
                </template>
                <template #default="scope">
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                        v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'del')"
                        v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-module ref="addModuleFlag" @refreshData="getData"></add-module>
    </div>
</template>
<script>
import addModule from "./components/addModuleKefu";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallKefu, fmallKefuDelete } from '@/api/api'
import { loadKeFuListApi,manageStatusKeFuApi } from '@/api/ke_fu.js'
import {loadSuperAgentListApi} from '@/api/super_agent.js'

export default {
    components: {
        Pagination,
        addModule
    },
    name: 'kefuManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0,
				appid:"1"
            },
            statusList: [
                { id: 1, name: '是' },
                { id: 0, name: '否' }
            ],
            tableData: [],
			super_agent_list:[]
        }
    },
    methods: {
		loadSuperAgent(){
			loadSuperAgentListApi().then(res=>{
				if(res.code==2000){
					this.super_agent_list=res.data
				}
			})
		},
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //当渲染的文字超出10字后显示省略号
        customEllipsis(value) {
            value = value.replace(/<.*?>/ig, "")       //把v-html的格式标签替换掉
            if (!value) return ""
            if (value.length > 10) {
                return value.slice(0, 10) + "..."
            }
            return value
        },
        addModule() {
            this.$refs.addModuleFlag.addModuleFn(null, '新增')
        },
        changeStatus(row) {
			let act=""
			let msg=""
			if (row.status){
				act="start"
				msg="确定启用该客服吗？"
			}
			if (!row.status){
				act="stop"
				msg="确定停用该客服吗？"
			}
			var that=this
			that.$confirm(msg, '提醒', {
			  closeOnClickModal: false,
			  type: "warning"
			}).then(res => {
				  manageStatusKeFuApi({id:row.id,act:act}).then(res=>{
					  that.$message(res.msg)
					  if(res.code==2000){
						  that.getData()
					  }else{
						  row.status=!row.status
					  }
					  
					  
				  })
				})
            // console.log(row,'row----')
        },
        handleEdit(row, flag) {
            let vm = this
            if (flag == 'edit') {
                vm.$refs.addModuleFlag.addModuleFn(row, '编辑')
            }
            else if (flag == 'del') {
                var that=this
                that.$confirm("确定删除该客服吗？", '提醒', {
                  closeOnClickModal: false,
                  type: "warning"
                }).then(res => {
                	  manageStatusKeFuApi({id:row.id,act:"del"}).then(res=>{
                		  that.$message(res.msg)
                		  if(res.code==2000){
                			  that.getData()
                		  }else{
                			  row.status=!row.status
                		  }
                		  
                		  
                	  })
                	})
            }
            
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        getData() {
            this.loadingPage = true
            loadKeFuListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }
		
    },
    created() {
		this.loadSuperAgent()
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },
    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
