<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="收货人：">
                                <el-input style="width:100%" v-model.trim="formInline.customer_name" maxlength="60" clearable
                                    placeholder="收货人" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="收货电话：">
                                <el-input style="width:100%" v-model.trim="formInline.customer_phone" maxlength="60" clearable
                                    placeholder="收货人电话号码" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单编号：">
                                <el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
                                    placeholder="用户下单的订单编号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        
                        <el-col :span="6">
                            <el-form-item label="退款类型：">
                                <el-select style="width:100%" v-model="formInline.refund_type" placeholder="请选择" clearable
                                    @change="search">
                                    <el-option v-for="item in retund_type_list" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        
						
						
                        <el-col :span="24">
                            <el-form-item label="">
                                    <el-form-item label="指定时段：">
                                        <el-date-picker style="width:100%" v-model="timers" type="datetimerange"
                                            @change="timeChange" range-separator="至" start-placeholder="订单归属日期开始时间"
                                            end-placeholder="订单归属日期结束时间">
                                        </el-date-picker>
                                    </el-form-item>
                                
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                                <!-- <el-button  type="primary" icon="Receiving" @click="exportOrder()" >导出订单商品</el-button> -->
                               
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

            </el-form>
        </div>
        <div>
        	<el-tabs v-model="act_tab" type="card" @tab-click="handleClick">
        		<el-tab-pane label="全部" name="0"></el-tab-pane>
        		<el-tab-pane label="已退款" name="10"></el-tab-pane>
        		<el-tab-pane label="未退款" name="20"></el-tab-pane>
        	</el-tabs>
        </div>
        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loading"
                @selection-change="handleSelectionChange">
                <el-table-column width="120" prop="id" label="序号" show-overflow-tooltip></el-table-column>
                <el-table-column width="150" prop="order_id" align="center" label="订单号" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="pos_no" align="center" label="货位号" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="customer_name" align="center" label="收货人"></el-table-column>
                <el-table-column width="120" prop="customer_phone" align="center" label="收货电话" show-overflow-tooltip></el-table-column>
                <el-table-column width="120" prop="agent_name" align="center" label="代理商" show-overflow-tooltip></el-table-column>
                
                <el-table-column width="200" prop="pay_time" align="center" label="订单支付时间" show-overflow-tooltip></el-table-column>
                <el-table-column width="140" prop="order_date"  align="center" color:red  label="订单日期" ></el-table-column>
                <el-table-column width="180" prop="refund_money" align="center" label="退款金额" ></el-table-column>
                <el-table-column width="200" prop="refund_type_name" align="center" label="退款类型" ></el-table-column>
                <el-table-column width="105"  align="center" label="退款状态">
                    <template #default="scope">
                        <el-tag v-if="scope.row.is_pay">已退</el-tag>
                        <el-tag v-if="!scope.row.is_pay">未退</el-tag>
                    </template>
                </el-table-column>
               

                <el-table-column label="操作" fixed="right" >
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                    
						<el-button type="primary" size="small" @click="handleDetail(scope.row)">详情</el-button>
						<el-button type="warning" size="small" @click="handleAction(scope.row)">退款</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <orderDetail ref="addModuleDetailFlag" v-if="showOrderDetail"
        	@closed="showOrderDetail = false">
        </orderDetail>
	</div>
</template>
<script>
import addModule from "./components/addModuleInsertion";
import updateRuWeiDialog from "./components/updateRuWei";

import orderDetail from "./components/orderDetailModule";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { loadOrdersProductListApi ,loadRefundListApi,agreeRefundApi} from '@/api/api'
import {getExportOrdersProductApi} from '@/api/export'
import {setStorage,getStorage} from '@/utils/util'
import axios from "axios";

export default {
    components: {
        Pagination,
       orderDetail
    },
    name: 'InsertionManage',
    data() {
        return {
			act_tab:"all",
            isFull: false,
            tableHeight: 500,
            loading: false,
            formInline: {
				refund_type:0,
                page: 1,
                limit: 10,
				product_from:0,
                
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            showOrderDetail:false,
            retund_type_list: [
				
                { id: 0, name: '全部类型' },
                { id: 1, name: '运费退款' },
                { id: 2, name: '缺货退款-有代理' },
                { id: 3, name: '缺货退款-无代理' },
                // { id: 4, name: '售后退款' },
                // { id: 5, name: '用户取消订单退款' },
            ],
			
            timers: [],
            tableData: [],
            isDialogVisible: false,
			show_update_ruwei_dialog:false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //多选项框被选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addModuleFlag.handleOpen(null, "新增")
            })
        },
		ruWeiAction(row){
			this.loadingSave = true
			updateRuWeiApi({id:row.id,
               reason_id:3}).then(res => {
			    this.loadingSave = false
			    if (res.code == 2000) {
			        this.$message.success(res.msg)
			        this.dialogVisible = false;
			        this.getData()
			    } else {
			        this.$message.warning(res.msg)
			    }
			})
		},
		
        handleRuwei(param) {
			
            // fmallOrdergoodsRuwei(param).then(res => {
            //     this.loadingSave = false
            //     if (res.code == 2000) {
            //         this.$message.success(res.msg)
            //         this.getData()
            //     } else {
            //         this.$message.warning(res.msg)
            //     }
            // })
        },
		select_date(e){
			console.log("select:",e)
			console.log("select:",this.formInline.select_date)
		},
        handleEdit(row, flag) {
            if (flag == 'ruwei') {
                if (row.ru_wei_count < row.count) {
                    this.isDialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.addModuleFlag.handleOpen(row, "缺货入位")
                    })
                } else {
                    let params = {
                        id: row.id,
                        ru_wei_count: row.ruwei_count,
                    }
                    this.handleRuwei(params)
                }

            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的数据吗？', {
                    closeOnClickModal: false
                }).then(res => {
                    fmallOrdergoodsDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
		handleAction(item){
			var that=this
			that.loading=true
			let msg="确定同意"+item.customer_name+"的退款金额："+item.refund_money+"?确定后款项将退回用户"
			that.$confirm(msg,"提示", {
			    closeOnClickModal: false
			}).then(res => {
			    agreeRefundApi({ id: item.id }).then(res => {
					that.loading=false
			        if (res.code == 2000) {
			            that.$message.success(res.msg)
			            that.search()
			        } else {
			            that.$message.warning(res.msg)
			        }
			    })
			}).catch(() => {
			
			})
		},
		handleDetail(item){
			this.showOrderDetail = true
			
			this.$nextTick(() => {
				this.$refs.addModuleDetailFlag.handleOpen(item, "订单详情", "normal")
			})
		},
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
			if(this.formInline.select_date=="today"){
				this.getNowDate()
			}
			if(this.formInline.select_date=="tomorrow"){
				this.getNextDate()
			}
			 this.tableData=[]
            this.getData()
        },
        //获取列表
        async getData() {
            this.loading = true
			
            loadRefundListApi(this.formInline).then(res => {
                this.loading = false
                if (res.code == 2000) {
                   
                    this.tableData = res.data.data.data_list
                   
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.start_time = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.end_time = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
				this.formInline.select_date=""
				this.formInline.order_date=""
            } else {
                this.formInline.start_time = null
                this.formInline.end_time = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        },
		updateRuWei(item){
			this.show_update_ruwei_dialog=true
			 this.$nextTick(() => {
			   this.$refs.updateRWDialogRef.handleOpen(item)
			   }
			   )
		},
		selectDate(e) {
			let timestamp = e.getTime()
			const dateObj = new Date(timestamp); // 创建Date对象
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			this.formInline.order_date = year + "-" + month + "-" + day
			this.formInline.select_date=""
			
		},
		getNextDate(){
			let dateObj = new Date();
			
			let hour=dateObj.getHours()
			let minutes=dateObj.getMinutes()
			
			
			if(hour>17){
				dateObj.setDate(dateObj.getDate()+1)
			}
			
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			
			this.formInline.order_date = year + "-" + month + "-" + day
		},
		getNowDate() {
			let dateObj = new Date();
			
			let hour=dateObj.getHours()
			let minutes=dateObj.getMinutes()
			
			
			if(hour<17){
				dateObj.setDate(dateObj.getDate()-1)
			}
			
			const year = String(dateObj.getFullYear()); // 获取年份
			const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
			const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
			
			this.formInline.order_date = year + "-" + month + "-" + day
		
			
		},
		async exportOrder(){
			 // console.log("url:",getExportOrdersPressUrl())
			 let token=getStorage('logintoken')
			const response=await axios({
				url:getExportOrdersProductApi(),
				headers:{
				    Authorization: 'JWT ' + token,
				},
				method:"GET",
				params:{order_date:this.formInline.order_date,product_from:this.formInline.product_from},
				responseType:'blob',
			})
			
			const url=window.URL.createObjectURL(new Blob([response.data]));
			const link=document.createElement('a');
			link.href=url;
			link.setAttribute('download',this.formInline.order_date+"_订单商品信息.xls")
			document.body.append(link);
			link.click()
			this.$message("请检查下载进度")
			// exprotOrderExpressApi({order_date:this.formInline.order_date}).then(res=>{
				
			// })
		},
	
		handleClick(tab, event) {
		
			this.act_tab = tab.props.name
			this.select_id_list=[]
			
			this.formInline.data_type = this.act_tab
			this.getData()
		
		},

    },
    created() {
		this.getNowDate()
        this.getData()
		
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
