<template>
	<div :class="{ 'ly-is-full': isFull }">
		<div class="tableSelect" ref="tableSelect">
			<el-form :inline="false" :model="formInline" label-width="auto">
				<div style="width: 100%;">
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="售后编号：">
								<el-input style="width:100%" v-model.trim="formInline.apply_no" maxlength="60" clearable
									placeholder="售后编号" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="订单编号：">
								<el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
									placeholder="订单编号" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="手机号：">
								<el-input style="width:100%" v-model.trim="formInline.phone" maxlength="60" clearable
									placeholder="手机号" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="收货人：">
								<el-input style="width:100%" v-model.trim="formInline.address_name" maxlength="60"
									clearable placeholder="收货人" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="售后状态：">
								<el-select style="width:100%" v-model="formInline.audit_status" placeholder="请选择"
									clearable @change="search">
									<el-option v-for="item in statusList" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="申请时间：">
								<el-date-picker style="width:100%" v-model="timers" type="datetimerange"
									@change="timeChange" range-separator="至" start-placeholder="开始时间"
									end-placeholder="结束时间">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="">
								<el-button @click="search" type="primary" icon="Search"
									v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
								<el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
							</el-form-item>
						</el-col>
					</el-row>

				</div>

			</el-form>

			<el-form :inline="false" :model="show_form" label-width="auto">
				<div style="width: 100%;">
					<el-row :gutter="20">
						<el-col :span="4">
							<el-form-item label="平台理赔(元)：">
								<el-input style="width:50%" v-model.trim="show_form.total_platform_refund" maxlength="30"
									readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="商家理赔(元)：">
								<el-input style="width:50%" v-model.trim="show_form.total_shop_refund" maxlength="30"
									readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="已处理(条)：">
								<el-input style="width:50%" v-model.trim="show_form.deal_apply_count" maxlength="30"
									readonly></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="4">
							<el-form-item label="待处理(条)：">
								<el-input style="width:50%" v-model.trim="show_form.wait_apply_count" maxlength="30"
									readonly></el-input>
							</el-form-item>
						</el-col>

					</el-row>

				</div>

			</el-form>
		</div>
		<div style="height: 50px;">
			<el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
				<el-tab-pane label="所有申请" name="all"></el-tab-pane>
				<el-tab-pane label="集花宝" name="jihuabao"></el-tab-pane>
				<el-tab-pane label="珍情" name="zhenqing"></el-tab-pane>
			          
			</el-tabs>
		</div>
       

		<div class="table" >
			
			<el-table :height="tableHeight" border :data="data_list" ref="tableref" v-loading="loadingPage"
				@selection-change="handleSelectionChange">
				<el-table-column width="80" prop="id" label="序号"></el-table-column>
				<el-table-column width="170" prop="order_no" label="售后编号"></el-table-column>
				<el-table-column width="170" prop="order_id" label="订单编号"></el-table-column>
				<el-table-column width="100" prop="product_from" label="商品来源"></el-table-column>
				<el-table-column width="140" prop="shop_name" label="商家名称"></el-table-column>
				<el-table-column width="100" prop="shop_code" label="商家编码"></el-table-column>
				<el-table-column width="100" prop="reason" label="售后原因"></el-table-column>
				<el-table-column width="150" prop="order_from" label="订单来源"></el-table-column>
				<el-table-column width="120" prop="status_name" label="售后状态"></el-table-column>
				<el-table-column min-width="160" prop="apply_time" label="申请时间"></el-table-column>
				<el-table-column min-width="160" prop="refund_status_name" label="退款到账"></el-table-column>
				<el-table-column min-width="160" prop="pay_time" label="下单时间"></el-table-column>
				<el-table-column label="操作" fixed="right" width="120">
					<template #header>
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div>操作</div>
							<div @click="setFull">
								<el-tooltip content="全屏" placement="bottom">
									<el-icon><full-screen /></el-icon>
								</el-tooltip>
							</div>
						</div>
					</template>
					<template #default="scope">
						<span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
							v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
						<span class="table-operate-btn" @click="handleEdit(scope.row, 'audit')"
							v-show="hasPermission(this.$route.name, 'Audit') && scope.row.audit_status == 10">审核</span>
					</template>
				</el-table-column>
			</el-table>
           <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
		</div>

		
		<applyDetail ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
		</applyDetail>

	</div>
</template>
<script>
	import applyDetail from "./components/applyDetail";
	import Pagination from "@/components/Pagination";
	import {
		dateFormats,
		getTableHeight
	} from "@/utils/util";
	import {
		fmallOrderaftersales,
		laodAdterSalesListApi
	} from '@/api/api'
	export default {
		components: {
			Pagination,
			applyDetail
		},
		name: 'serviceOrderListManage',
		data() {
			return {
				activeTab: "all",
				isFull: false,
				tableHeight: 200,
				loadingPage: false,
				formInline: {
					data_type:"all",
					page: 1,
					limit: 10,
				},
				defaultImg: require('../../assets/img/avatar.jpg'),
				pageparm: {
					
					page: 1,
					limit: 10,
					total: 0
				},
				statusList: [{
						id: 10,
						name: '待审核'
					},
					{
						id: 20,
						name: '已通过'
					},
					{
						id: 30,
						name: '未通过'
					},
				],
				timers: [],
				tableData: [],
				isDialogVisible: false,
				// 选项框选中数组
				ids: [],
				// 选项框非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				show_form: {
					wait_apply_count: 0,
					deal_apply_count: 0,
					total_shop_refund: 0,
					total_platform_refund: 0,
					total_apply_count:0,
				},
				data_list: [],
				apply_info:{}
			}
		},

		methods: {
			handleClick(tab, event) {
				this.active_tab = tab.props.name
				this.data_list = []
				this.formInline.page=1
				this.formInline.data_type=tab.props.name
				this.getData()

			},
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			setFull() {
				this.isFull = !this.isFull
				window.dispatchEvent(new Event('resize'))
			},
			//多选项框被选中数据
			handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id);
				this.single = selection.length !== 1;
				this.multiple = !selection.length;
			},
			handelAddClick() {
				this.isDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addModuleFlag.handleOpen(null, "新增")
				})
			},
			handleEdit(row, flag) {
				if (flag == 'detail') {
					this.isDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleFlag.handleOpen(row, "详情", false, )
					})
				} else if (flag == 'audit') {
					this.isDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleFlag.handleOpen(row, "审核", true)
					})
				} else if (flag == "reset") {
					this.formInline = {
						page: 1,
						limit: 10
					}
					this.pageparm = {
						page: 1,
						limit: 10,
						total: 0
					}
					this.getData()
				}
			},
			/**
			 * 从URL里下载文件
			 */
			// 下载文件
			downloadFileURL(url) {
				var iframe = document.createElement("iframe")
				iframe.style.display = "none";
				iframe.src = url;
				document.body.appendChild(iframe);
			},
			exportDataBackend() {
				var params = {
					page: 1,
					limit: 9999,
				}
				// UsersUsersExportexecl(params).then(res => {
				//     if (res.code == 2000) {
				//         this.downloadFileURL(res.data.data)
				//         //this.$message.warning(res.data.data)
				//     }
				// })
			},
			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				this.getData()
			},
			search() {
				this.active_tab="all"
				this.formInline.data_type="all"
				this.formInline.page = 1
				this.formInline.limit = 10
				this.getData()
			},
			//获取列表
			async getData() {
				this.loadingPage = true
				laodAdterSalesListApi(this.formInline).then(res => {
					this.loadingPage = false
				
					if (res.code == 2000) {
						this.data_list = res.data.data.data_list
						this.show_form=res.data.data.data
						this.pageparm.page = res.data.data.page;
						this.pageparm.limit = res.data.data.limit;
						this.pageparm.total = res.data.data.total;
					}
				})
			},

			timeChange(val) {
				if (val) {
					this.formInline.start_time = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
					this.formInline.end_time = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
				} else {
					this.formInline.start_time = null
					this.formInline.end_time = null
				}
				this.search()
			},
			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
				tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
				this.tableHeight = getTableHeight(tabSelectHeight)-50
			}

		},
		created() {
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			window.addEventListener('resize', this.listenResize);
			this.$nextTick(() => {
				this.getTheTableHeight()
			})
		},

		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>