<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="标签名称：" prop="name">
                    <el-input v-model.trim="formData.name" :disabled="loadingTitle=='详情'"></el-input>
                </el-form-item>
                <!-- <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" :min="0" :max="999999" style="width: 100%;"></el-input-number>
                </el-form-item> -->
    <!--            <el-form-item label="状态：" prop="status" :disabled="loadingTitle=='详情'" >-->
    <!--                <el-switch-->
    <!--                    v-model="formData.status"-->
    <!--                    active-color="#13ce66"-->
    <!--                    inactive-color="#ff4949">-->
    <!--                </el-switch>-->
    <!--            </el-form-item>-->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave" :disabled="loadingTitle=='详情'">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {fmallLabelsAdd,fmallLabelsEdit,addAttrApi} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    export default {
        emits: ['refreshData','closed'],
        name: "addModuleLabel",
        components: {LyDialog},
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
                    name:"",
                    parent:"",
                    is_label:true,
                },
                rules:{
                    name: [
                        {required: true, message: '请输入标签名称',trigger: 'blur'}
                    ],
                }
            }
        },
        methods:{
            handleClose() {
                this.$emit('closed')
            },
            handleOpen(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData=item
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.id){
                            fmallLabelsEdit(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }else{
                            addAttrApi(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }
                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

