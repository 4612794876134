<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="容量：" prop="volume">
          <el-input v-model="formData.volume"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {ExpressBoxAdd, ExpressBoxEdit} from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"
import TEditor from "@/components/TEditor.vue";

export default {
  name: "addExpressBox",
  components: {TEditor, LyDialog},
  emits: ['refreshData', 'closed'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
        code: '',
        name: '',
      },
      rules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ], volume: [
          {required: true, message: '请输入容量', trigger: 'blur'}
        ]
      },
      areaList: [],
    }
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    handleOpen(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        delete this.rules.password
        this.formData = deepClone(item)
      } else {
        // this.rules.password = [
        //     { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
      }
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (this.formData.id) {
            ExpressBoxEdit(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.dialogVisible = false;
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            ExpressBoxAdd(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

