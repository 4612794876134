<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" :title="loadingTitle" @closed="handleClose">
			<div v-if="loadingTitle!='打款结果'">
				<el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="100px">
				  	
				    <el-form-item label="银行名称：" >
				        {{ formData.bank_name }}
				    </el-form-item>
				    <el-form-item label="银行卡号：" >
				        {{ formData.bank_no }}
				    </el-form-item>
				    <el-form-item label="真实姓名：" >
				      {{ formData.real_name }}
				    </el-form-item>
				    <el-form-item label="申请金额：">
				        {{ formData.money }}
				    </el-form-item>
				    <el-form-item label="审核结果：" prop="action">
				        <el-radio-group v-model="formData.action" :disabled=" !isaudit ">
				            <el-radio :label="1">审核通过</el-radio>
				            <el-radio :label="2">审核未通过</el-radio>
				        </el-radio-group>
				    </el-form-item>
				    <el-form-item label="未通过原因：" prop="audit_remarks">
				        <el-input type="textarea" v-model.trim="formData.remark" :disabled=" !isaudit" maxlength="200"
				            show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
				    </el-form-item>
				</el-form>
				
			</div>
			<div v-if="loadingTitle=='打款结果'">
				<el-form :model="formData"  ref="rulesForm" label-position="right" label-width="100px">
					
					<el-form-item label="打款结果：" prop="is_pay">
					   <el-radio-group v-model="formData.is_pay" :disabled="true">
					       <el-radio :label="1">已打款</el-radio>
					       <el-radio :label="0">未打款</el-radio>
					   </el-radio-group>
					</el-form-item>
				</el-form>
			</div>
           <template v-slot:footer>
			   <div v-if="loadingTitle!='打款结果'">
				   <el-button  type="primary" @click="submitData" :loading="loadingSave" v-if="isaudit">提交</el-button>
			   </div>
               <div v-if="loadingTitle=='打款结果'">
				   <el-button  type="primary" @click="closeTips" :loading="loadingSave" >关闭</el-button>
			   </div>
           </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { fmallBlcRecordAudit } from "@/api/api";
import { auditShopApplyApi } from "@/api/finance";
import { deepClone } from "@/utils/util";
export default {
    name: "auditModuleCashout",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            tempdata: "",
            formData: {
                id: '',
                action: 0,
                remark: '',
            },
            rules: {
                action: [
                    {required: true, message: '请选择审核结果',trigger: 'blur'}
                ]
            },
            statusList: [
                { id: 'pass', name: "审核通过" },
                { id: 'deny', name: "审核未通过" },
            ],
            isaudit: false
        }
    },
    methods: {
		closeTips(){
			this.dialogVisible=false
		},
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag, isaudit) {
            this.dialogVisible = true
            console.log("flag:",flag,flag=="transfer")
			if(flag=="transfer"){
				this.loadingTitle="打款结果"
				 this.formData = deepClone(item.transfer_info)
			}else{
				 this.loadingTitle = flag
				this.isaudit = isaudit
				if (item) {
				    this.formData = deepClone(item)
					this.formData.action = this.formData.status
					if(this.formData.status>0){
						this.isaudit=false
					}
				    // if (this.formData.audit_status == 0 ) {
				    //     this.formData.action = this.formData.audit_status
				    // } else {
				    //     this.formData.action = 20
				    // }
				}
			}
            
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
					let audit_action=""
					if(this.formData.action==1){
						audit_action="agree"
					}
					if(this.formData.action==2){
						audit_action="reject"
					}
					this.operateAudit(audit_action,[this.formData.id])
                   
                }
            })
        },
		operateAudit(audit_action,id_list){
			let netData = {
			    'audit_action': audit_action,
			    'remark': this.formData.remark,
			    'id_list': id_list
			}
			this.loadingSave = true
			auditShopApplyApi(netData).then(res => {
			    this.loadingSave = false
			    if (res.code == 2000) {
			        this.$message.success(res.msg)
			        this.handleClose()
			        this.$emit('refreshData')
			    } else {
			        this.$message.warning(res.msg)
			    }
			})
		}
		

    }
}
</script>

