import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadCouponListApi = (params={}) =>ajaxGet({url:'coupon/list', params})
export const addCouponApi = (params={}) =>ajaxPost({url:'coupon/add', params})
export const editCouponApi = (params={}) =>ajaxPost({url:'coupon/edit', params})

export const mangeCouponStatusApi = (params={}) =>ajaxPost({url:'coupon/manage/status', params})
export const loadCustomerCouponListApi = (params={}) =>ajaxGet({url:'customer/coupon/list', params})