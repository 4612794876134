<template>
	<div>
		<ly-dialog v-model="dialogVisible" :title="loadingTitle" width="600px" @closed="handleClose">

			<el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
				label-width="auto">
				<el-form-item label="代理名称：" prop="name">
					<el-input v-model="formData.name" placeholder="代理商名称:如 昆明代理商"></el-input>
				</el-form-item>
				<el-form-item label="头像：" prop="avatar">
					<LyUploadAvatar v-model="formData.avatar" width="80px" height="80px"></LyUploadAvatar>
				</el-form-item>
				<el-form-item label="手机号：" prop="phone">
					<el-input v-model="formData.phone" placeholder="手机号将作为登录账号"></el-input>
				</el-form-item>

				<el-form-item label="密码：" prop="password">
					<el-input v-model="formData.password" :readonly="formData.id>0"></el-input>
				</el-form-item>
				<el-form-item label="真实姓名：" prop="real_name">
					<el-input v-model="formData.real_name"></el-input>
				</el-form-item>

				<el-form-item label="主花提成：" prop="zhu_hua_fee">
					<el-input v-model="formData.zhu_hua_fee"></el-input>
				</el-form-item>
				<el-form-item label="配花分成：" prop="pei_hua_fee">
					<el-input v-model="formData.pei_hua_fee"></el-input>
				</el-form-item>
				<!-- <el-form-item label="代理城市：" prop="city_code_list"> -->
				<el-form-item label="代理城市：" required>
					<!-- @change="handleChange" -->
					<el-select v-model="formData.province_code" placeholder="省" @change="provinceChange"
						style="width: 120px;">
						<el-option v-for="item in province_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="formData.city_code" placeholder="市" @change="cityChange" style="width: 130px;">
						<el-option v-for="item in city_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select multiple collapse-tags v-model="formData.county_code_list" placeholder="区/县"
						@change="countyChange" style="width: 195px;">
						<el-option v-for="item in county_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<!-- <el-cascader style="width: 100%" :show-all-levels="false" :options="agent_area_list" v-model="formData.city_code_list"
		                  
		                   @change="handleChange"
		                   :props="{multiple:true, label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
		                   filterable></el-cascader> -->
				</el-form-item>
				
				<el-form-item label=" ">
					<el-table  border :data="formData.county_table_list" ref="tableref">
					 
					  <el-table-column width="90" prop="county" label="区县"></el-table-column>
					
					  <el-table-column width="120"  label="主花加价">
						  <template #default="scope">
						       <el-input v-model="scope.row.add_price_zhu_hua"></el-input>
						   </template>
					  </el-table-column>
					  <el-table-column width="120"  label="配花加价">
						  <template #default="scope">
						       <el-input v-model="scope.row.add_price_pei_hua"></el-input>
						   </template>
					  </el-table-column>
					  
					  <el-table-column label="操作" fixed="right" >
					    <template #header>
					      <div style="display: flex;justify-content: space-between;align-items: center;">
					        <div>操作</div>
					      </div>
					    </template>
					    <template #default="scope">
					                  
					      <span class="table-operate-btn" @click="handleEdit(scope.row, 'supply')" style="font-size: 10px;">一键填充</span>
					      <span class="table-operate-btn" @click="handleEdit(scope.row, 'del')" style="color: orangered;">删除</span>
					    </template>
					  </el-table-column>
					</el-table>
					
				</el-form-item>
				

				<el-form-item label="收货地址："  required>
					<el-select v-model="formData.express_province_code" placeholder="省" @change="expressProvinceChange"
						style="width: 120px;">
						 <el-option v-for="item in express_province_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select v-model="formData.express_city_code" placeholder="市" @change="expressCityChange" style="width: 130px;">
						<el-option v-for="item in express_city_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					<el-select  collapse-tags v-model="formData.express_county_code" placeholder="区/县"
						@change="expressCountyChange" style="width: 155px;">
						<el-option v-for="item in express_county_list" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
					
					<!-- @change="handleChange" -->
					<!-- <el-cascader style="width: 100%" :show-all-levels="false" :options="express_county_list"
						v-model="formData.express_county_code" @change="handleCountyChange"
						:props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
						filterable></el-cascader> -->
				</el-form-item>

				<el-form-item label="收货详细地址：" prop="address">
					<el-input v-model="formData.address"></el-input>
				</el-form-item>

				<el-form-item label="收货物流：" prop="express_name">
					<el-select v-model="formData.express_name" placeholder="请选择" style="width: 100%;" filterable
						clearable>
						<el-option v-for=" item  in  express_list " :key="item.id" :label="item.name"
							:value="item.name" />
					</el-select>
				</el-form-item>

				<!-- <el-form-item label="区域主花加价：" prop="add_price_zhu_hua">
					<div style="display: flex;">
						<el-input v-model="formData.add_price_zhu_hua" style="width: 100px" :readonly="formData.id>0"></el-input>
						<p v-if="formData.county_code_list && formData.id<1" style="margin-left: 12px;font-size: 12px;">该加价对所选区/县有效</p>
						<p v-if="formData.county_code_list && formData.id>0" style="margin-left: 12px;font-size: 12px;">若需修改区域加价请到"区域价格管理页面修改"</p>
					</div>
					
				</el-form-item>

				<el-form-item label="区域配花加价：" prop="add_price_pei_hua">
					<div style="display: flex;">
					  <el-input v-model="formData.add_price_pei_hua" style="width: 100px" :readonly="formData.id>0"></el-input>
					  <p v-if="formData.county_code_list && formData.id<1" style="margin-left: 12px;font-size: 12px;">该加价对所选区/县有效</p>
					  <p v-if="formData.county_code_list && formData.id>0" style="margin-left: 12px;font-size: 12px;">若需修改区域加价请到"区域价格管理页面修改"</p>
					  </div>
				</el-form-item> -->
				
				<el-form-item label="关联管理/员工：" prop="admin_id">
					<el-select v-model="formData.admin_id" placeholder="请选择" style="width: 100%;" filterable
						clearable>
						<el-option v-for=" item  in  admin_list " :key="item.id" :label="item.name"
							:value="item.id" />
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="状态：" prop="is_active">
		        <el-switch v-model="formData.is_active" active-color="#13ce66" inactive-color="#ff4949">
		        </el-switch>
		    </el-form-item> -->
			</el-form>
			<template #footer>
				<el-button @click="handleClose" :loading="loadingSave">取消</el-button>
				<el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
			</template>



		</ly-dialog>
	</div>
</template>

<script>
	import {
		UsersAgentUsersAdd,
		UsersAgentUsersEdit,
		getAllAreaslist,
		getAreaPriceApi
	} from "@/api/api";
	import {
		loadExpressListApi
	} from "@/api/express";
	import{loadAdminListApi} from "@/api/system"
	import LyDialog from "@/components/dialog/dialog";
	import LyUploadAvatar from "@/components/upload/avatar";
	import {
		deepClone
	} from "@/utils/util"

	export default {
		name: "addUserAgent",
		components: {
			LyDialog,
			LyUploadAvatar
		},
		emits: ['refreshData', 'closed'],
		data() {
			return {
				dialogVisible: false,
				loadingSave: false,
				loadingTitle: '',
				defaultImg: require('../../../assets/img/avatar.jpg'),
				province_list: [],
				city_list: [],
				county_list: [],
				
				express_province_list: [],
				express_city_list: [],
				express_county_list: [],
				
				
				formData: {
					id: 0,
					phone: '',
					real_name: '',
					name: '',
					zhu_hua_fee: 0,
					pei_hua_fee: 0,

					avatar: '',
					adcode: '',
					cityname: '',
					
					province: '',
					province_code: '',
					city: '',
					city_code: '',
					county: '',
					county_code: '',
					county_code_list: [],
					county_table_list:[],
					
					express_province_code:"",
					express_province:"",
					
					express_city_code:"",
					express_city:"",
					
					express_county: "",
					express_county_code: "",
					address: '',
					add_price_zhu_hua: 0,
					add_price_pei_hua: 0,
					express_name: "",
					admin_id:null,

				},
				rules: {
					avatar: [{
						required: true,
						message: '请上传头像',
						trigger: 'blur'
					}],
					real_name: [{
						required: true,
						message: '请输入真实姓名',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入代理商名称',
						trigger: 'blur'
					}],
					// password: [
					//   {required: true, message: '请输入密码', trigger: 'blur'}
					// ],
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}],
					zhu_hua_fee: [{
						required: true,
						message: '请输入主花提成',
						trigger: 'blur'
					}],
					pei_hua_fee: [{
						required: true,
						message: '请输入配花提成',
						trigger: 'blur'
					}],
					province: [{
						required: true,
						message: '请选择代理商所在省份',
						trigger: 'blur'
					}],
					city: [{
						required: true,
						message: '请选择代理商所在市/州',
						trigger: 'blur'
					}],
					// county: [{
					// 	required: true,
					// 	message: '请选择代理商收货地所在的区/县',
					// 	trigger: 'blur'
					// }],
					
					express_province: [{
						required: true,
						message: '请选择代理商收货地址所在省份',
						trigger: 'blur'
					}],
					express_city: [{
						required: true,
						message: '请选择代理商收货地址所在市/州',
						trigger: 'blur'
					}],
					express_county: [{
						required: true,
						message: '请选择代理商收货地址收货地所在的区/县',
						trigger: 'blur'
					}],

					address: [{
						required: true,
						message: '请填写代理商具体地址',
						trigger: 'blur'
					}],
					add_price_zhu_hua: [{
						required: true,
						message: '请填写代理商主花加价',
						trigger: 'blur'
					}],
					add_price_pei_hua: [{
						required: true,
						message: '请填写代理商主花加价',
						trigger: 'blur'
					}],
					// is_active: [
					//     { required: true, message: '请选择是否启用', trigger: 'blur' }
					// ]
				},
				express_county_list: [],
				agent_area_list: [],
				express_list: [],
				all_area_list: [],
				admin_list:[]
			}
		},
		methods: {
			handleClose() {
				this.$emit('closed')
			},
			getAllAreaslistData() {
				getAllAreaslist({
					subdistrict: 3
				}).then(res => {
					if (res.code === 2000) {
						this.all_area_list = res.data.data
						let area_list = deepClone(this.all_area_list)
						let agent_area_list = []
						if (area_list) {
							area_list.forEach(province => {
								let pro = province
								pro.childlist = []

								this.province_list.push(pro)
								this.express_province_list.push(pro)
								// if(province.childlist){
								//  province.childlist.forEach(city=>{
								//   city.childlist=[]
								//  })
								// }
								// agent_area_list.push(province)

							})
							this.provinceChange(this.formData.province_code)
							
							this.cityChange(this.formData.city_code)
							console.log("city_code:",this.formData.city_code)
							this.expressProvinceChange(this.formData.express_province_code)
							this.expressCityChange(this.formData.express_city_code)

						}

						//       this.agent_area_list = agent_area_list

						// if(this.all_area_list){
						//  this.all_area_list.forEach(province=>{
						//   if(province.childlist){
						// 	  province.childlist.forEach(city=>{
						// 		  if(city.id==this.formData.city_code){
						// 			  this.county_list=city.childlist
						// 		  }
						// 	  })
						//   }
						//  })
						// }

					}
				})
			},
			handleCountyChange(e) {

				this.county_list.forEach(item => {
					if (item.id == e) {

						this.formData.express_county = item.name
						this.formData.express_county_code = item.id
					}
				})

			},
			provinceChange(pro_code) {
                this.city_list=[]
				
				if(this.loadingTitle=="新增"){
					  this.formData.county_code_list=[]
					  this.formData.county_table_list=[]
					  
					  this.formData.city=""
					  this.formData.city_code=""
					}
			
				
				this.all_area_list.forEach(pro => {

					if (pro.id == pro_code) {
						this.formData.province = pro.name
						this.formData.province_code = pro.id
						this.city_list = pro.childlist
					}
				})
			},
			cityChange(city_code) {
				if(this.loadingTitle=="新增"){
					this.formData.county_code_list=[]
					this.formData.county_table_list=[]
				}
				
				this.all_area_list.forEach(pro => {
					if (pro.childlist) {
						pro.childlist.forEach(city => {
							if (city.id == city_code) {
								this.formData.city = city.name
								this.formData.city_code = city.id
								this.county_list = [{
									id: "0",
									name: '所有辖区'
								}]
								this.county_list.push(...city.childlist)

								this.express_county_list = []
								this.express_county_list.push(...city.childlist)
							}
						})
					}
				})
				// this.getAddPrice()
			},
			countyChange(county_code_list) {
				
				let all_checked=false
				county_code_list.forEach(code=>{
					if(code=="0"){
						all_checked=true
					}
				})
				if(this.loadingTitle=="新增"){
					this.formData.county_table_list=[]
					this.formData.county_code_list = []
				}
				
				if(all_checked){
					this.county_list.forEach(item=>{
						if(item.name!="所有辖区"){
							this.formData.county_code_list.push(item.id)
							let row={
										"county_code":item.id,
										"county":item.name,
										"add_price_zhu_hua":0,
										"add_price_pei_hua":0
									}
								
							this.formData.county_table_list.push(row)
						}
						
					})
				}else{
					county_code_list.forEach(code=>{
						this.county_list.forEach(item=>{
							if(item.id==code && item.name!="所有辖区"){
								this.formData.county_code_list.push(item.id)
								let row={
											"county_code":item.id,
											"county":item.name,
											"add_price_zhu_hua":0,
											"add_price_pei_hua":0
										}
									
								this.formData.county_table_list.push(row)
							}
						})
					})
				}
				
				
				// this.formData.county_table_list=[]
				// this.formData.county_code_list = []
				
				// if (county_code_list == "0") {
					
				// 	this.county_list.forEach(county => {
				// 		this.formData.county_code_list.push(county.id)
				// 		let row={
				// 			"county_code":county.id,
				// 			"county":county.name,
				// 			"add_price_zhu_hua":0,
				// 			"add_price_pei_hua":0
				// 		}
					
				// 		this.formData.county_table_list.push(row)
				// 	})

				// } else {
				// 	county_code_list.forEach(code => {
				// 		if (code == "0") {
				// 			this.formData.county_table_list=[]
				// 			this.formData.county_code_list = []
							
				// 			this.county_list.forEach(county => {
				// 				this.formData.county_code_list.push(county.id)
								
				// 				let row={
				// 					"county_code":county.id,
				// 					"county":county.name,
				// 					"add_price_zhu_hua":0,
				// 					"add_price_pei_hua":0
				// 				}
													
				// 				this.formData.county_table_list.push(row)
								
				// 			})
				// 		}else{
				// 			this.county_list.forEach(county => {
								
				// 				if(code==county.id && !this.formData.county_code_list.includes(code)){
				// 					this.formData.county_code_list.push(county.id)
									
				// 					let row={
				// 						"county_code":county.id,
				// 						"county":county.name,
				// 						"add_price_zhu_hua":0,
				// 						"add_price_pei_hua":0
				// 					}
														
				// 					this.formData.county_table_list.push(row)
				// 				}
								
								
				// 			})
							
				// 		}
				// 	})
				// }

			},
			expressProvinceChange(pro_code) {
			   
				this.all_area_list.forEach(pro => {
			
					if (pro.id == pro_code) {
						this.formData.express_province = pro.name
						this.formData.express_province_code = pro.id
						this.express_city_list = pro.childlist
					}
				})
			},
			expressCityChange(city_code) {
				
						this.express_city_list.forEach(city => {
							if (city.id == city_code) {
								this.formData.express_city = city.name
								this.formData.express_city_code = city.id
								
								this.express_county_list = []
								this.express_county_list.push(...city.childlist)
							}
						})
					
				
				// this.getAddPrice()
			},
			expressCountyChange(county_code) {
				
					this.county_list.forEach(item=>{
					if(item.id==county_code){
						this.formData.express_county=item.name
						this.formData.express_county_code=item.id
					}
					
						})
				
			},
			
			handleEdit(row,action){
				if(action=="del"){
					    let msg="确定将此区域:"+row.county+"从表格中移除吗？"
						this.$confirm("",'提示',{
							confirmButtonText:'确定',
							cancelButtonText:'取消',
							type:'warning'
						}).then(()=>{
							let county_table_list=this.formData.county_table_list.filter((item)=>{
								return item.county_code!=row.county_code
							})
							
							this.formData.county_table_list=county_table_list
							this.formData.county_code_list=this.formData.county_code_list.filter(code=>{
								return code!=row.county_code
							})
						}).catch(()=>{
							
						})
						
					
					
					
					
					
				}
			  if(action=="supply"){
				
				  	this.$confirm("确定将此区域的加价填充到其它区域吗？",'提示',{
				  		confirmButtonText:'确定',
				  		cancelButtonText:'取消',
				  		type:'warning'
				  	}).then(()=>{
				  		this.formData.county_table_list.forEach(item=>{
				  							  item.add_price_zhu_hua=row.add_price_zhu_hua
				  							  item.add_price_pei_hua=row.add_price_pei_hua
				  		})
				  	}).catch(()=>{
				  		
				  	})
				  	
				
				  
				 
			  }
			},
			handleChange(e) {

				let agent_city_code = ""
				this.formData.county_code_list = e
				if (e) {
					this.all_area_list.forEach(pro => {
						if (pro.childlist.length > 0) {
							pro.childlist.forEach(city => {
								if (city.childlist.length > 0) {
									city.childlist.forEach(county => {
										if (e.includes(county.id)) {
											this.formData.province = pro.name
											this.formData.province_code = pro.id
											this.formData.city = city.name
											this.formData.city_code = city.id
										}
									})

								}
							})
						}
					})
				} else {
					this.formData.province = ""
					this.formData.province_code = ""
					this.formData.city = ""
					this.formData.city_code = ""
				}

				//     this.all_area_list.forEach(province => {
				//       if (!!province.childlist && province.childlist.length > 0) {
				//         province.childlist.forEach(city => {
				//           if (city.id == e) {

				// 	  this.formData.province=province.name
				// 	  this.formData.province_code=province.id
				// 	  this.formData.city=city.name
				// 	  this.formData.city_code=city.id

				// 	  this.county_list=city.childlist
				//             this.formData.cityname = province.name + city.name


				//           }
				//         })
				//       }

				//     })




			},
			getAddPrice(){
				getAreaPriceApi({city_code:this.formData.city_code}).then(res=>{
					if(res.code==2000){
						this.formData.add_price_zhu_hua=res.data.add_price_zhu_hua
						this.formData.add_price_pei_hua=res.data.add_price_pei_hua
					}
				})
			},
			loadAdminList(){
				loadAdminListApi({page:1,limit:99,status:"1"}).then(res=>{
					if(res.code==2000){
						this.admin_list=res.data.data
					}
				})
			},
			handleOpen(item, flag) {

				this.loadingTitle = flag
				this.dialogVisible = true
				if (item) {
                    console.log("item:",item)
					this.formData =deepClone(item) 
					console.log("form:",this.formData)
				}
				this.getAllAreaslistData()
				this.loadExpress()
				this.loadAdminList()



			},
			loadExpress() {
				loadExpressListApi({
					status: 1,
					limit: 99
				}).then(res => {
					if (res.code == 2000) {
						this.express_list = res.data.data.data_list

					}
				})
			},
			submitData() {
				this.$refs['rulesForm'].validate(obj => {
					if (obj) {
						this.loadingSave = true
						let param = {
							...this.formData
						}
						let county_code_list = param.county_code_list.filter((county_code) => {
							return county_code != "0"
						})
						param.county_code_list = county_code_list

						if (this.formData.id) {
							UsersAgentUsersEdit(param).then(res => {
								this.loadingSave = false
								if (res.code == 2000) {
									this.$message.success(res.msg)
									this.dialogVisible = false;
									this.handleClose()
									this.$emit('refreshData')
								} else {
									this.$message.warning(res.msg)
								}
							})
						} else {
							UsersAgentUsersAdd(param).then(res => {
								this.loadingSave = false
								if (res.code == 2000) {
									this.$message.success(res.msg)
									this.handleClose()
									this.$emit('refreshData')
								} else {
									this.$message.warning(res.msg)
								}
							})
						}

					}
				})
			},
		}
	}
</script>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 128px;
		height: 128px;
		line-height: 128px;
		text-align: center;
	}

	.avatar {
		width: 128px;
		height: 128px;
		display: block;
	}
</style>