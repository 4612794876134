<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" :before-close="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
		<el-form-item label="归属超级代理：" prop="appid">
			<el-select v-model="formData.appid" placeholder="请选择" style="width: 100%;" filterable clearable>
			    <el-option v-for=" item  in  super_agent_list " :key="item.appid" :label="item.name" :value="item.appid" />
			</el-select>
			
		
		</el-form-item>	   
			   
        <el-form-item label="客服名称：" prop="name">
          <el-input v-model.trim="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="mobile">
          <el-input v-model.trim="formData.mobile"></el-input>
        </el-form-item>
        <el-form-item label="微信号：" prop="weixin">
          <el-input v-model="formData.weixin"></el-input>
        </el-form-item>
        <el-form-item label="企业微信链接：" prop="weixin_link">
          <el-input v-model="formData.weixin_link"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="mark">
          <el-input v-model="formData.mark"></el-input>
        </el-form-item>
        <el-form-item label="客服头像：" prop="avatar">
          <LyUploadAvatar v-model="formData.avatar" width="100px" height="100px"></LyUploadAvatar>
        </el-form-item>
        <el-form-item label="二维码：" prop="weixin_avatar">
          <LyUploadAvatar v-model="formData.weixin_avatar" width="100px" height="100px"></LyUploadAvatar>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {fmallKefuAdd, fmallKefuEdit} from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import {deepClone} from "@/utils/util"
import {loadSuperAgentListApi} from '@/api/super_agent.js'
import {addKeFuApi,editKeFuApi} from '@/api/ke_fu.js'

export default {
  components: {LyDialog, LyUploadAvatar},
  emits: ['refreshData'],
  name: "addModuleKefu",
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
		appid:"1",
        name: '',
        avatar: '',
        mobile: '',
        weixin: '',
        weixin_link: '',
        mark: '',
        weixin_avatar: '',
      },
	  super_agent_list:[],
      rules: {
        name: [
          {required: true, message: '请填写名称', trigger: 'blur'}
        ],
		appid: [
          {required: true, message: '请选择归属的超级代理', trigger: 'blur'}
        ],
        // mobile: [
        //     { required: true, message: '请填写手机号', trigger: 'blur' }
        // ],
        // weixin: [
        //     { required: true, message: '请填写微信号', trigger: 'blur' }
        // ],
        weixin_link: [
          {required: true, message: '请填写企业微信链接', trigger: 'blur'}
        ],
        // avatar: [
        //     { required: true, message: '请上传头像', trigger: 'blur' }
        // ],
        // weixin_avatar: [
        //     { required: true, message: '请上传二维码', trigger: 'blur' }
        // ],
      },
    }
  },
  mounted() {
    window.addEventListener("focusin", this.onFocusIn, true);
  },
  unmounted() {
    window.removeEventListener("focusin", this.onFocusIn);
  },
  methods: {
    onFocusIn(e) {
      e.stopImmediatePropagation()//阻止当前和后面的一系列事件
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.formData = {
        name: '',
        avatar: '',
        mobile: '',
        weixin: '',
        weixin_avatar: '',
      }
    },
    addModuleFn(item, flag) {
	  this.loadSuperAgent()
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        this.formData = deepClone(item)
      }
    },
	loadSuperAgent(){
		loadSuperAgentListApi().then(res=>{
			if(res.code==2000){
				this.super_agent_list=res.data
			}
		})
	},
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (this.formData.id) {
            editKeFuApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            addKeFuApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },

  }
}
</script>

<style>
.set-specs .el-form-item__content {
  background: #e6e6e6 !important;
}
</style>

