<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
      <el-form :inline="true" :model="formInline" label-position="left">
		<el-form-item label="区域名称：">
		    <el-input v-model.trim="formInline.name" maxlength="80" clearable placeholder="请输入地区名进行查询" @change="search"
		        style="width:130px"></el-input>
		</el-form-item>
		<el-form-item label=""><el-button @click="search" type="primary" icon="Search"
		        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                                          icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handelAddClick" type="primary" icon="Plus"
                                          v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
        <el-table-column prop="id" width="60" align="center" label="序号">
          <!--  <template #default="scope">
                <span v-text="getIndex(scope.$index)"></span>
            </template> -->
        </el-table-column>
        <el-table-column min-width="170" prop="server_area" label="开通地区">
			
		</el-table-column>
        <el-table-column min-width="170" prop="agent_name" label="服务商名称">
			<template #default="scope">
			    <span v-if="scope.row.agent_id<=0" style="color: crimson;">未开通服务商</span>
			    <!-- <span v-if="scope.row.agent_id>0">未开通服务商</span> -->
			</template>
		</el-table-column>
        <el-table-column min-width="150" prop="add_price_zhu_hua" label="主花加价"></el-table-column>
        <el-table-column min-width="150" prop="add_price_pei_hua" label="配花加价"></el-table-column>
        <el-table-column min-width="100" label="使用状态">
          <template #default="scope">
			  <el-button v-if="scope.row.status" type="primary" @click="mangeStatus(scope.row.id,'stop')">使用中</el-button>
			  <el-button v-if="!scope.row.status" type="warning" @click="mangeStatus(scope.row.id,'start')">未使用</el-button>
            <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                       @change="changeStatus(scope.row)">
            </el-switch> -->
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="180">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                              v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
            <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                  v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <addAreaPrice ref="addAreaPriceFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
    </addAreaPrice>
  </div>
</template>
<script>
import addAreaPrice from "./components/addAreaPrice";
import Pagination from "@/components/Pagination";
import {dateFormats, getTableHeight, hasPermission} from "@/utils/util";
import {
  AreaPriceManageStatusApi,
  AreaPriceSearchApi,

} from '@/api/api'
export default {
  components: {
    Pagination,
    addAreaPrice
  },
  name: 'areaPriceManager',
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
        page: 1,
        limit: 10,
		name:''
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      identityList: [
        { id: 0, name: '普通用户' },
        { id: 1, name: '会员' },
      ],
      timers: [],
      tableData: [],
      isDialogVisible: false,
    }
  },

  methods: {
    hasPermission,
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
	mangeStatus(id,act){
		let msg=""
		if(act=='start'){
			msg='确定启用区域加价吗'
		}
		if(act=='stop'){
			msg='确定停用区域加价吗'
		}
		
		this.$confirm(msg,'提醒',{
			closeOnClickModal: false,
			type: "warning"
		}).then(res=>
		{
			AreaPriceManageStatusApi({ id: id,act:act }).then(res => {
			  if (res.code == 2000) {
			    this.$message.success(res.msg)
			    this.getData()
			  } else {
			    this.$message.warning(res.msg)
			  }
			})
		})
	},
    changeStatus(row) {
      let vm = this
      let flat = row.status
      row.status = !row.status
      let temp_is_allow = 1
      vm.$confirm('确定修改状态吗？', '提醒', {
        closeOnClickModal: false,
        type: "warning"
      }).then(res => {
        flat ? row.status = true : row.status = false
        if (row.status) {
          temp_is_allow = 1
        } else {
          temp_is_allow = 0
        }
		let act=''
		if(row.status){
			act='stop'
		}else{
			act='start'
		}
        AreaPriceManageStatusApi({ id: row.id,act:act }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.getData()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }).catch(() => {

      })
    },
    handelAddClick() {
      this.isDialogVisible = true
      this.$nextTick(() => {
        this.$refs.addAreaPriceFlag.handleOpen(null, "新增")
      })
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.isDialogVisible = true
        this.$nextTick(() => {
          this.$refs.addAreaPriceFlag.handleOpen(row, "编辑")
        })
      }
      else if (flag == 'disable') {
        let vm = this
		let act=''
		if(row.status){
			act='stop'
		}else{
			act='start'
		}
        AreaPriceManageStatusApi({ id: row.id ,act:act }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          AreaPriceManageStatusApi({ id: row.id ,act:'del'}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0
        }
        this.getData()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportDataBackend() {
      var params = {
        page: 1,
        limit: 9999,
      }
      // UsersUsersExportexecl(params).then(res => {
      //     if (res.code == 2000) {
      //         this.downloadFileURL(res.data.data)
      //         //this.$message.warning(res.data.data)
      //     }
      // })
    },
    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      // this.formInline.limit = 10
      this.getData()
    },
	
    //获取列表
    async getData() {
      this.loadingPage = true
      AreaPriceSearchApi(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
			let data=res.data
			
          this.tableData =data.data.data_list
          this.pageparm.page = data.page;
          this.pageparm.limit = data.limit;
          this.pageparm.total = data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    }

  },
  created() {
    this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
