<template>
	<div :class="{ 'ly-is-full': isFull }">
		<div class="tableSelect" ref="tableSelect">
			<el-form :inline="false" :model="formInline" label-width="auto">
				<div style="width: 100%;">
					<el-row :gutter="20">
						<el-col :span="6">
							<el-form-item label="订单编号：">
								<el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
									placeholder="订单编号" @change="search"></el-input>
							</el-form-item>
						</el-col>

						<el-col :span="6">
							<el-form-item label="用户昵称：">
								<el-input style="width:100%" v-model.trim="formInline.nick_name" maxlength="60"
									clearable placeholder="用户昵称" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="手机号：">
								<el-input style="width:100%" v-model.trim="formInline.phone" maxlength="60" clearable
									placeholder="手机号" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="收货人：">
								<el-input style="width:100%" v-model.trim="formInline.address_name" maxlength="60"
									clearable placeholder="收货人" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="6">
                <el-form-item label="所属商家：">
                    <el-input style="width:100%" v-model.trim="formInline.shopname" maxlength="60" clearable
                        placeholder="所属商家" @change="search"></el-input>
                </el-form-item>
            </el-col> -->
						<el-col :span="6">
							<el-form-item label="所属代理：">
								<el-input style="width:100%" v-model.trim="formInline.agent_name" maxlength="60"
									clearable placeholder="所属代理" @change="search"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="订单状态：">
								<el-select style="width:100%" v-model="formInline.status" placeholder="请选择" clearable
									@change="search">
									<el-option v-for="item in statusList" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="类型：">
								<el-select style="width:100%" v-model="formInline.order_type" placeholder="请选择"
									clearable @change="search">
									<el-option v-for="item in typeList" :key="item.id" :label="item.name"
										:value="item.id">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="支付时间：">
								<el-date-picker style="width:100%" v-model="timers" type="daterange"
									@change="timeChange" range-separator="至" start-placeholder="开始时间"
									end-placeholder="结束时间">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="18">
							<el-col :span="6">
								<el-form-item label="归属日期：">
									<el-date-picker style="width:100%" v-model="formInline.order_date" type="date"
										@change="selectDate" placeholder="订单归属日期">
									</el-date-picker>
								</el-form-item>
							</el-col>

							<el-form-item label="">
								<el-radio-group v-model="formInline.is_agent_send" style="margin-right: 12px;"
									@change="search">
									<el-radio-button label="">所有订单</el-radio-button>
									<el-radio-button :label="0">待配送订单</el-radio-button>
								</el-radio-group>
								<el-button @click="search" type="primary" icon="Search"
									>查询
								</el-button>
								<el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
								<!-- <el-button @click="handleEdit('', 'reset')" type="warning" icon="MessageBox"
                    :disabled="multiple">批量发货</el-button> -->
								<el-button v-show="hasPermission(this.$route.name, 'printOrder')"
									@click="printBatchData()" type="info" icon="Printer"
									:disabled="activeName=='all'">批量打印货单
								</el-button>
								
								<el-button v-show="hasPermission(this.$route.name, 'printOrder')"
									@click="exportOrder()" type="primary" icon="Receiving"
									>导出订单物流信息
								</el-button>
								<!-- <el-button @click="fmallOrderAgentSend(ids)" type="primary" icon="Promotion"
									:disabled="ids.length === 0"
									v-show="hasPermission(this.$route.name, 'agentSend')">一键配送
								</el-button> -->
							</el-form-item>
						</el-col>
					</el-row>
				</div>

			</el-form>
		</div>
		<div>
			<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
				<el-tab-pane label="所有订单" name="all"></el-tab-pane>
				<el-tab-pane label="代理商订单" name="agent"></el-tab-pane>
				<el-tab-pane label="散客订单" name="normal"></el-tab-pane>

			</el-tabs>
		</div>


		<div v-show="activeName=='all'">
			<!-- <div class="table"> -->
			<el-table :height="tableHeight" border default-expand-all row-key="order_id" :data="all_order_list"
				ref="tableref" v-loading="loadingPage" @selection-change="handleSelectionChange">
				<!-- <el-table-column type="selection" width="55" align="center"></el-table-column> -->
				<el-table-column min-width="150" prop="order_id" label="订单编号"></el-table-column>
				<el-table-column width="100" prop="goods_no" label="货架号"></el-table-column>
				<!-- <el-table-column min-width="110" prop="customer_nick_name" label="收货人"></el-table-column> -->

				<el-table-column min-width="110" prop="address_name" label="收货人"></el-table-column>
				<el-table-column min-width="110" prop="address_phone" label="手机号"></el-table-column>
				<el-table-column min-width="110" prop="total_orders_money" label="订单总金额"></el-table-column>
				<el-table-column min-width="110" prop="status_name" label="订单状态"></el-table-column>
				<!-- <el-table-column min-width="110" prop="username" label="售后状态"></el-table-column> -->
				<!-- <el-table-column min-width="110" prop="ruwei_type_name" label="入位类型"></el-table-column> -->
				<!-- <el-table-column min-width="110" prop="username" label="所属商家"></el-table-column> -->
				<el-table-column width="120" prop="agent_name" label="所属代理"></el-table-column>
				<el-table-column min-width="170" prop="pay_time" label="支付时间"></el-table-column>
				<el-table-column min-width="150" prop="order_date" label="归属日期"></el-table-column>
				<el-table-column width="120" prop="express_name" fixed="right" label="物流"></el-table-column>
				<el-table-column label="操作" fixed="right" width="280">
					<template #header>
						<div style="display: flex;justify-content: space-between;align-items: center;">
							<div>操作</div>
							<div @click="setFull">
								<el-tooltip content="全屏" placement="bottom">
									<el-icon>
										<full-screen />
									</el-icon>
								</el-tooltip>
							</div>
						</div>
					</template>
					<template #default="scope">
						<!-- <template> -->
						<span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
							v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
							
						<span class="table-operate-btn" @click="setOrderApplyStatus(scope.row)"
							>同意售后</span>
							
						<span class="table-operate-btn" @click="cancelOrder(scope.row)"
							v-show="hasPermission(this.$route.name, 'Retrieve')" style="color: orangered;">取消订单</span>
						

					</template>
					
				</el-table-column>
			</el-table>
			<div>
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="formInline.page" :page-sizes="[ 10, 15, 20,30]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
				</el-pagination>
			</div>


		</div>
		<div v-show="activeName=='agent'" style="display: flex;">
			
			<div class="right" style="width: 100%;">
				

				<div class="table">
					<el-table :height="tableHeight-40" border default-expand-all row-key="order_id"
						:data="agent_order_list" ref="agentTable" v-loading="loadingPage"
						@selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column min-width="100" prop="agent_name" label="代理商"></el-table-column>
						<el-table-column min-width="100" prop="agent_area" label="地区"></el-table-column>
						<!-- <el-table-column min-width="180" prop="order_id" label="订单编号"></el-table-column> -->
						<el-table-column width="80" prop="goods_no" label="货架号"></el-table-column>

						<!-- <el-table-column min-width="110" prop="address_name" label="收货人"></el-table-column> -->
						<el-table-column min-width="100" prop="total_product_count" label="商品总数量"></el-table-column>
						<!-- <el-table-column min-width="120" prop="address_phone" label="手机号"></el-table-column> -->
						<el-table-column min-width="100" prop="total_orders_money" label="订单总金额"></el-table-column>
						<el-table-column width="150" prop="order_date" label="归属日期"></el-table-column>
						
						<el-table-column width="120" prop="express_name" fixed="right" label="物流"></el-table-column>
						<el-table-column label="操作" fixed="right" width="350">
							<template #header>
								<div style="display: flex;justify-content: space-between;align-items: center;">
									<div>操作</div>
									<div @click="setFull">
										<el-tooltip content="全屏" placement="bottom">
											<el-icon>
												<full-screen />
											</el-icon>
										</el-tooltip>
									</div>
								</div>
							</template>
							<template #default="scope">
								<span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
									v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
								<!-- <span class="table-operate-btn" @click="handleEdit(scope.row, 'ruwei')"
									v-show="hasPermission(this.$route.name, 'Ruwei') && scope.row.status == 20">入位</span> -->
								<span v-if="scope.row.send_status==0" class="table-operate-btn"
									@click="handleEdit(scope.row, 'package')"v-show="hasPermission(this.$route.name, 'Deliver') ">打包</span>
								<span v-if="scope.row.send_status==1" class="table-operate-btn"
									@click="handleEdit(scope.row, 'weight')"
									v-show="hasPermission(this.$route.name, 'Deliver') ">称重</span>
								<span v-if="scope.row.send_status==2" class="table-operate-btn"
									@click="handleEdit(scope.row, 'sendGoodsManual')"
									v-show="hasPermission(this.$route.name, 'Deliver') ">发货</span>
								<span class="table-operate-btn" @click="handleEdit(scope.row, 'record')"
									v-show="hasPermission(this.$route.name, 'OperateLog')">操作记录</span>
								<span class="table-operate-btn" v-show="hasPermission(this.$route.name, 'printOrder')"
									@click="agentPrint(scope.row.agent_id)">打印货单</span>
								<!-- <template v-else> -->
								<!-- <span class="table-operate-btn" v-show="hasPermission(this.$route.name, 'printOrder')"
			                @click="handleEdit(scope.row, 'agentPrintOrder')">批量打印</span> -->
								<!-- <span class="table-operate-btn"
										v-show="hasPermission(this.$route.name, 'printOrder')"
										@click="handleEdit(scope.row, 'agentPrintSelf')">单独打印</span> -->
								<!-- <span class="table-operate-btn" @click="handleEdit(scope.row, 'agentSendGoodsManual')"
			                v-show="hasPermission(this.$route.name, 'Deliver')">合并发货</span> -->
								<!-- </template> -->

							</template>
						</el-table-column>
					</el-table>
				</div>
				<div>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="formInline.page" :page-sizes="[ 10, 15, 20,30]" :page-size="10"
						layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
					</el-pagination>
				</div>
			</div>


		</div>


		<div v-show="activeName=='normal'">
			<div class="table">
				<el-table :height="tableHeight" border default-expand-all row-key="order_id" :data="normal_order_list"
					ref="tableref" v-loading="loadingPage" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column min-width="200" prop="order_id" label="订单编号"></el-table-column>
					<el-table-column width="100" prop="goods_no" label="货架号"></el-table-column>
					<!-- <el-table-column min-width="110" prop="customer_nick_name" label="收货人"></el-table-column> -->

					<el-table-column min-width="110" prop="address_name" label="收货人"></el-table-column>
					<el-table-column min-width="110" prop="address_phone" label="手机号"></el-table-column>
					<el-table-column min-width="110" prop="total_orders_money" label="订单总金额"></el-table-column>
					<el-table-column min-width="110" prop="status_name" label="订单状态"></el-table-column>
					<!-- <el-table-column min-width="110" prop="username" label="售后状态"></el-table-column> -->
					<!-- <el-table-column min-width="110" prop="ruwei_type_name" label="入位类型"></el-table-column> -->
					<!-- <el-table-column min-width="110" prop="username" label="所属商家"></el-table-column> -->
					<!-- <el-table-column width="120" prop="agent_name" label="所属代理"></el-table-column> -->
					<el-table-column min-width="150" prop="pay_time" label="下单时间"></el-table-column>
					<el-table-column min-width="150" prop="order_date" label="归属日期"></el-table-column>
					<el-table-column width="120" prop="express_name" fixed="right" label="物流"></el-table-column>
					<el-table-column label="操作" fixed="right" width="280">
						<template #header>
							<div style="display: flex;justify-content: space-between;align-items: center;">
								<div>操作</div>
								<div @click="setFull">
									<el-tooltip content="全屏" placement="bottom">
										<el-icon>
											<full-screen />
										</el-icon>
									</el-tooltip>
								</div>
							</div>
						</template>
						<template #default="scope">
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
								v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'ruwei')"
								v-show="hasPermission(this.$route.name, 'Ruwei') && scope.row.status == 20">入位</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'package')"
								v-show="hasPermission(this.$route.name, 'Deliver') && scope.row.status == 30 && scope.row.platform_send_status==0">打包</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'weight')"
								v-show="hasPermission(this.$route.name, 'Deliver') && scope.row.status == 30 && scope.row.platform_send_status==1">称重</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'sendGoodsManual')"
								v-show="hasPermission(this.$route.name, 'Deliver') && scope.row.status == 30 && scope.row.platform_send_status==2">发货</span>
							<span class="table-operate-btn" @click="handleEdit(scope.row, 'record')"
								v-show="hasPermission(this.$route.name, 'OperateLog')">操作记录</span>
							<span class="table-operate-btn" v-show="hasPermission(this.$route.name, 'printOrder')"
								@click="normalPrint(scope.row.id)">打印货单</span>


						</template>
					</el-table-column>
				</el-table>
			</div>
			<div>
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="formInline.page" :page-sizes="[ 10, 15, 20,30]" :page-size="10"
					layout="total, sizes, prev, pager, next, jumper" :total="formInline.total">
				</el-pagination>
			</div>
		</div>
		<!-- <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination> -->
		<addModule ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
		</addModule>
		<addModuleYunFei ref="addModuleYunfeiFlag" @refreshData="getData" v-if="isYunfeiDialogVisible"
			@closed="isYunfeiDialogVisible = false">
		</addModuleYunFei>
		<orderDetail ref="addModuleDetailFlag" v-if="isDetailDialogVisible"
			@closed="isDetailDialogVisible = false">
		</orderDetail>
		
		<agentOrderDetail ref="agentOrderDetailRef" v-if="show_agent_order_detail"
			@closed="show_agent_order_detail = false" @handleDetail="handleOrderDetail">
			</agentOrderDetail>
		
		<addModuleMallOrderOPRecord ref="addModuleRecordFlag" v-if="isRecordlDialogVisible"
			@closed="isRecordlDialogVisible = false">
		</addModuleMallOrderOPRecord>
		<moduleDeliverGoodsManual @refreshData="getData" ref="moduleDeliverGoodsManualFlag"
			v-if="isDeliverGoodsManualVisible" @closed="isDeliverGoodsManualVisible = false">
		</moduleDeliverGoodsManual>
		<modulePrintOrder ref="modulePrintOrderFlog"></modulePrintOrder>
		<modulePrintOrderMultiple ref="moduleMPrintOrderFlog"></modulePrintOrderMultiple>

		<addPackageModule @refreshData="getData" ref="packageModuleDialog" v-if="is_show_package"
			@closed="is_show_package = false"></addPackageModule>
	</div>
</template>
<script>
	import addModule from "./components/addModule";
	import addModuleYunFei from "./components/addModuleYunFei";
	import orderDetail from "./components/orderDetailModule";
	import agentOrderDetail from "./components/agentOrderDetail.vue";
	
	import addModuleMallOrderOPRecord from "./components/addModuleMallOrderOPRecord";
	import moduleDeliverGoodsManual from "./components/moduleDeliverGoodsManual";
	import modulePrintOrderMultiple from "./components/modulePrintOrderMultiple";
	import modulePrintOrder from "./components/modulePrintOrder";

	import addPackageModule from "./components/packageModule";

	import Pagination from "@/components/Pagination";
	import {
		dateFormats,
		getTableHeight,
		hasPermission
	} from "@/utils/util";
	import {
		fmallOrder,
		fmallOrderAgentSend,
		fmallOrderDelete,
		getOrderListApi,
		getOrdersAgentListApi,
        cancelOrderApi,
		setOrderApplyAfterStatusAPi
	} from '@/api/api'
	
	import{loadAgentPrintListApi} from '@/api/print'
	import{exprotOrderExpressApi} from '@/api/express'
	import {getExportOrdersPressUrl} from '@/api/export'
	
	import {setStorage,getStorage} from '@/utils/util'
import axios from "axios";

	export default {
		components: {
			Pagination,
			addModule,
			addModuleYunFei,
			orderDetail,
			addModuleMallOrderOPRecord,
			moduleDeliverGoodsManual,
			modulePrintOrderMultiple,
			modulePrintOrder,
			addPackageModule,
			agentOrderDetail
		},
		name: 'mallOrderListManage',
		data() {
			return {
				activeName: 'all',
				is_show_package: false,

				isFull: false,
				tableHeight: 460,
				loadingPage: false,
				formInline: {
					is_agent_send: '',
					page: 1,
					limit: 30,
					total: 0,
					start_time: "",
					end_time: "",
					tag_order_type: "",
				},
				defaultImg: require('../../assets/img/avatar.jpg'),
				pageparm: {
					page: 1,
					limit: 30,
					total: 0
				},
				statusList: [
					// {
					// 	id: 10,
					// 	name: '待支付'
					// },
					{
						id: 20,
						name: '待入位'
					},
					{
						id: 30,
						name: '待发货'
					},
					{
						id: 40,
						name: '待收货'
					},
					{
						id: 50,
						name: '待评价'
					},
					{
						id: 60,
						name: '已完成'
					},
					// {
					// 	id: 70,
					// 	name: '已取消'
					// },
				],
				typeList: [{
						id: 1,
						name: '零售'
					},
					{
						id: 2,
						name: '秒杀'
					},
					{
						id: 3,
						name: '团购'
					},
				],
				timers: [],
				tableData: [],

				isDialogVisible: false,
				isYunfeiDialogVisible: false,
				isDetailDialogVisible: false,
				isRecordlDialogVisible: false,
				isDeliverGoodsManualVisible: false,
				show_agent_order_detail:false,
				// 选项框选中数组
				ids: [],
				// 选项框非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,

				all_order_list: [],
				agent_list: [],
				normal_order_list: [],
				agent_order_list: [],
				select_agent: {
					name: ""
				},

				agentQuery: {
					agent_total: 0,
					page: 1,
					limit: 15
				},
				select_id_list:[]

			}
		},

		methods: {
			hasPermission,
			// 表格序列号
			getIndex($index) {
				// (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
				return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
			},
			selectAgent(row, colunm, event) {

				this.formInline.agent_id = row.agent_id
				this.select_agent.name = row.agent_name
				this.getData()
			},
			setOrderApplyStatus(item){
				
				let msg="确定同意用户:"+item.address_name+" 的订单延长售后申请时间吗？该订单订单编号："+item.order_id+",请确定后再操作！"
				let vm = this
				vm.$confirm(msg, {
					closeOnClickModal: false
				}).then(res => {
					setOrderApplyAfterStatusAPi({
						order_id: item.order_id
					}).then(res => {
						if (res.code == 2000) {
							vm.$message.success(res.msg)
							
						} else {
							vm.$message.warning(res.msg)
						}
					})
				}).catch(() => {
				
				})
				
			   
			},
			handleClick(tab, event) {

				this.activeName = tab.props.name
				this.select_id_list=[]
				// console.log(this.activeName)
				// if (this.activeName == "agent") {
				// 	this.loadAgent()
				// }

				this.formInline.tag_order_type = this.activeName
				this.getData()

			},
			loadAgent() {
				getOrdersAgentListApi({
					page: 1,
					limit: 99
				}).then(res => {
					if (res.code == 2000) {
						this.agent_list = res.data.data_list
						this.agentQuery.agent_total = res.data.total
					}
				})
			},
			setFull() {
				this.isFull = !this.isFull
				window.dispatchEvent(new Event('resize'))
			},
			//多选项框被选中数据
			handleSelectionChange(selection) {
			    var that=this
				this.select_id_list=[]
				if(this.activeName=="agent"){
					selection.forEach(item=>{
						that.select_id_list.push(item.agent_id)
					})
					// this.select_id_list=selection.map[item=>item.agent_id]
					console.log("sel",that.select_id_list)
				}
				if(this.activeName=="normal"){
					selection.forEach(item=>{
						that.select_id_list.push(item.id)
					})
					
					// this.select_id_list=selection.map[item=>item.id]
				}
				// this.ids = selection.map(item => item.id);
				// this.single = selection.length !== 1;
				// this.multiple = !selection.length;
			},
			handelAddClick() {
				this.isDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addModuleFlag.handleOpen(null, "新增")
				})
			},
			fmallOrderAgentSend(ids) {
				fmallOrderAgentSend({
					ids: ids
				}).then(res => {
					if (res.code == 2000) {
						this.$message.success(res.msg)
						this.search()
					} else {
						this.$message.warning(res.msg)
					}
				})
			},
			handleOrderDetail(item){
				this.isDetailDialogVisible = true
				this.$nextTick(() => {
					this.$refs.addModuleDetailFlag.handleOpen(item, "订单详情", "normal")
				})
			},
			cancelOrder(item){
				let msg="确定取消用户:"+item.address_name+"的订单吗？该订单订单编号："+item.order_id+",请确定后再操作！"
				let vm = this
				vm.$confirm(msg, {
					closeOnClickModal: false
				}).then(res => {
					cancelOrderApi({
						order_id: item.order_id
					}).then(res => {
						if (res.code == 2000) {
							vm.$message.success(res.msg)
							
						} else {
							vm.$message.warning(res.msg)
						}
					})
				}).catch(() => {
				
				})
			},
			handleEdit(row, flag) {
				let act_type = ""
				if (this.activeName == "agent") {
					act_type = "agent"
				}
				if (this.activeName == "normal" || this.activeName=="all") {
					act_type = "normal"
				}

				if (flag == 'detail') {
					if(act_type=="agent"){
						this.show_agent_order_detail=true;
						this.$nextTick(()=>{
							this.$refs.agentOrderDetailRef.handleOpen(row.id,row.agent_name+"_代理商订单详情"+"("+this.formInline.order_date+")",this.formInline.order_date)
						})
					}else{
						this.isDetailDialogVisible = true
						this.$nextTick(() => {
							this.$refs.addModuleDetailFlag.handleOpen(row, "订单详情", act_type)
						})
					}
					
				

				} else if (flag == "sendGoodsManual") {
					this.isDeliverGoodsManualVisible = true
					let act_type = ""
					if (this.activeName == "agent") {
						act_type = "agent"
					}
					if (this.activeName == "normal") {
						act_type = "normal"
					}
					this.$nextTick(() => {
						this.$refs.moduleDeliverGoodsManualFlag.handleOpen(row, "发货", act_type)
					})
				} else if (flag == "agentSendGoodsManual") {
					this.isDeliverGoodsManualVisible = true
					this.$nextTick(() => {
						this.$refs.moduleDeliverGoodsManualFlag.handleOpen(row.children, "合并发货")
					})
				} else if (flag == 'agentSend') {
					this.fmallOrderAgentSend([row.id])
				} else if (flag == 'ruwei') {
					this.$router.push({
						name: 'InsertionManage',
						query: {
							order_id: row.order_id
						}
					});
				} else if (flag == 'record') {
					this.isRecordlDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleRecordFlag.handleOpen(row, "操作记录")
					})
				} else if (flag == 'yunfei') {
					this.isYunfeiDialogVisible = true
					this.$nextTick(() => {
						this.$refs.addModuleYunfeiFlag.handleOpen(row, "运费")
					})
				} else if (flag == 'printOrder' || flag == 'agentPrintSelf') {
					this.$refs.modulePrintOrderFlog.handleOpen(row)
				} else if (flag == 'mPrintOrder') {
					this.$refs.moduleMPrintOrderFlog.handleOpen(this.ids)
				} else if (flag == 'agentPrintOrder') {
					const ids = [row.id]
					row.children.forEach(item => ids.push(item.id))
					this.$refs.moduleMPrintOrderFlog.handleOpen(ids)
				} else if (flag == "weight") {

					this.is_show_package = true
					let act_type = ""
					if (this.activeName == "agent") {
						act_type = "agent"
					}
					if (this.activeName == "normal") {
						act_type = "normal"
					}
					this.$nextTick(() => {
						this.$refs.packageModuleDialog.handleOpen(row, "weight", act_type,this.formInline.order_date)
					})
				} else if (flag == "package") {

					this.is_show_package = true
					this.$nextTick(() => {
						let act_type = ""
						if (this.activeName == "agent") {
							act_type = "agent"
						}
						if (this.activeName == "normal") {
							act_type = "normal"
						}
						this.$refs.packageModuleDialog.handleOpen(row, "package", act_type,this.formInline.order_date)
					})
				} else if (flag == 'delete') {
					let vm = this
					vm.$confirm('您确定要删除选中的数据吗？', {
						closeOnClickModal: false
					}).then(res => {
						fmallOrderDelete({
							id: row.id
						}).then(res => {
							if (res.code == 2000) {
								vm.$message.success(res.msg)
								vm.search()
							} else {
								vm.$message.warning(res.msg)
							}
						})
					}).catch(() => {

					})
				} else if (flag == "reset") {
					this.formInline = {
						page: 1,
						limit: 10
					}
					this.pageparm = {
						page: 1,
						limit: 10,
						total: 0
					}
					 this.getNowDate()
					this.timers = []
					this.getData()
				}
			},
			/**
			 * 从URL里下载文件
			 */
			// 下载文件
			downloadFileURL(url) {
				var iframe = document.createElement("iframe")
				iframe.style.display = "none";
				iframe.src = url;
				document.body.appendChild(iframe);
			},
			exportDataBackend() {
				var params = {
					page: 1,
					limit: 9999,
				}
				// UsersUsersExportexecl(params).then(res => {
				//     if (res.code == 2000) {
				//         this.downloadFileURL(res.data.data)
				//         //this.$message.warning(res.data.data)
				//     }
				// })
			},
			callFather(parm) {
				this.formInline.page = parm.page
				this.formInline.limit = parm.limit
				this.getData()
			},
			search() {
				this.formInline.page = 1
				this.formInline.limit = 10
				this.getData()
			},
			//获取列表
			async getData() {

				
				this.loadingPage = true
				if (!!this.$route.query.creator_id) {
					this.formInline.creator_id = this.$route.query.creator_id
				}
				if (!!this.$route.query.order_id) {
					this.formInline.order_id = this.$route.query.order_id
				}
				if(this.formInline.start_time){
					this.formInline.order_date=''
				}
				
				
				// getOrderListApi
				// fmallOrder(this.formInline).then(res => {
				getOrderListApi(this.formInline).then(res => {
					this.loadingPage = false
					
					if (res.code == 2000) {
						let data = res.data
						if (data.data.tag_order_type == 'agent') {
							this.agent_order_list = data.data.data_list
						}

						if (data.data.tag_order_type == 'normal') {

							this.normal_order_list = data.data.data_list
						} else {
							this.all_order_list = res.data.data.data_list
						}
						// this.tableData = res.data.data
						// this.pageparm.page = res.data.page;
						// this.pageparm.limit = res.data.limit;
						this.formInline.total = res.data.total;
					}else{
						this.$message(res.msg)
					}
				})
			},

			timeChange(val) {
				if (val) {
					this.formInline.start_time = dateFormats(val[0], 'yyyy-MM-dd');
					this.formInline.end_time = dateFormats(val[1], 'yyyy-MM-dd');
					this.order_date=""
				} else {
					this.formInline.start_time = null
					this.formInline.end_time = null
				}
				this.search()
			},
			// 计算搜索栏的高度
			listenResize() {
				this.$nextTick(() => {
					this.getTheTableHeight()
				})
			},
			getTheTableHeight() {
				let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
				tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
				this.tableHeight = getTableHeight(tabSelectHeight) - 40
			},

			//分页相关
			handleSizeChangeAgent(val) {
				this.agentQuery.limit = val
				this.loadAgent()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChangeAgent(val) {
				this.agentQuery.page = val
				this.loadAgent()
				console.log(`当前页: ${val}`);
			},

			handleSizeChange(val) {
				this.formInline.limit = val
				this.getData()
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.formInline.page = val
				this.getData()
				console.log(`当前页: ${val}`);
			},
			selectDate(e) {
				let timestamp = e.getTime()
				const dateObj = new Date(timestamp); // 创建Date对象
				const year = String(dateObj.getFullYear()); // 获取年份
				const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
				const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
				this.formInline.order_date = year + "-" + month + "-" + day
				// console.log("select_order_date", e.getTime(), "__", )
			},
			getNowDate() {
				let dateObj = new Date();
				
				let hour=dateObj.getHours()
				let minutes=dateObj.getMinutes()
				
				
				if(hour<17){
					dateObj.setDate(dateObj.getDate()-1)
				}
				
				const year = String(dateObj.getFullYear()); // 获取年份
				const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // 获取月份，并补零
				const day = String(dateObj.getDate()).padStart(2, "0"); // 获取日期，并补零
				
				this.formInline.order_date = year + "-" + month + "-" + day

				
			},
			agentPrint(agent_id){
				this.select_id_list=[]
				this.select_id_list.push(agent_id)
				this.printBatchData()
			},
			normalPrint(parent_id){
				this.select_id_list=[]
				this.select_id_list.push(parent_id)
				this.printBatchData()
			},
			printBatchData(){
				// this.$refs.moduleMPrintOrderFlog.handleOpen({id_list:this.select_id_list,order_date:this.formInline.order_date})
				this.$refs.modulePrintOrderFlog.handleOpen({id_list:this.select_id_list,order_date:this.formInline.order_date,data_type:this.activeName})
				// this.printData(this.select_id_list)
			},
			printData(data_list){
			
				if(data_list.length<1){
					this.$message("请先选择要打印的数据")
					return
				}
				
				loadAgentPrintListApi({
					id_list:JSON.stringify(data_list) ,
					order_date:this.formInline.order_date
				}).then(res=>{
					if(res.code==2000){
						console.log(res.data)
					}
				})
			},
			 async exportOrder(){
				 // console.log("url:",getExportOrdersPressUrl())
				 let token=getStorage('logintoken')
				const response=await axios({
					url:getExportOrdersPressUrl(),
					headers:{
					    Authorization: 'JWT ' + token,
					},
					method:"GET",
					params:{order_date:this.formInline.order_date},
					responseType:'blob',
				})
				
				const url=window.URL.createObjectURL(new Blob([response.data]));
				const link=document.createElement('a');
				link.href=url;
				link.setAttribute('download',this.formInline.order_date+"_物流交接信息.xls")
				document.body.append(link);
				link.click()
				this.$message("请检查下载进度")
				// exprotOrderExpressApi({order_date:this.formInline.order_date}).then(res=>{
					
				// })
			}
		

	},
	created() {
		    this.getNowDate()
			this.getData()
		},
		mounted() {
			// 监听页面宽度变化搜索框的高度
			window.addEventListener('resize', this.listenResize);
			this.$nextTick(() => {
				this.getTheTableHeight()
			})
		},

		unmounted() {
			// 页面销毁，去掉监听事件
			window.removeEventListener("resize", this.listenResize);
		},
	}
</script>
<style>
	.el-table .warning-row {
		background: oldlace;
	}

	.el-table .success-row {
		background: #f0f9eb;
	}
</style>