<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="640px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <!--<el-form-item label="管理员编号：" prop="id">-->
                    <!--<el-input v-model.trim="formData.id" style="width: 300px"></el-input>-->
                <!--</el-form-item>-->
                <el-form-item label="管理员名称：" prop="name">
                    <el-input v-model="formData.name"></el-input>
                </el-form-item>
				
				
                <!--<el-form-item label="权限字符：" prop="name">
                    <el-input v-model.trim="formData.name" style="width: 300px"></el-input>
                </el-form-item>-->
                <el-form-item label="登录账号：" prop="account">
                    <el-input :readonly="formData.id<1?false:true" v-model.trim="formData.account"></el-input>
                </el-form-item>
				
				<view v-if="edit_type=='重置密码'">
					<el-form-item  label="登录密码：" prop="password">
					        <el-input v-model.trim="formData.password" placeholder="请输入新的登录密码"></el-input>
					    </el-form-item>
				</view>
				
				<view v-if="edit_type!='重置密码'">
					<el-form-item v-if="formData.id<1" label="登录密码：" prop="password">
					        <el-input v-model.trim="formData.password"></el-input>
					    </el-form-item>
						
						<el-form-item label="真实姓名：" prop="real_name">
					        <el-input v-model.trim="formData.real_name"></el-input>
					    </el-form-item>
						<el-form-item label="联系电话：" prop="phone">
					        <el-input v-model.trim="formData.phone"></el-input>
					    </el-form-item>
					<!-- 	<el-form-item label="是否是超管：" prop="is_superuser">
					       <el-radio v-model="formData.is_superuser" label="false">否</el-radio>
					         <el-radio v-model="formData.is_superuser" label="true">是</el-radio>
					    </el-form-item> -->
					    <!--<el-form-item label="排序：" prop="sort">-->
					        <!--<el-input-number v-model="formData.sort" :min="1" :max="999999"></el-input-number>-->
					    <!--</el-form-item>-->
					    <el-form-item label="角色：" prop="role">
					        <el-checkbox-group v-model="formData.roles">
					            <el-checkbox :label="item.id" v-for="(item,index) in rolelist" :key="index">{{item.name}}</el-checkbox>
					        </el-checkbox-group>
					    </el-form-item>
					    <!-- <el-form-item label="部门：" prop="dept">
					        <el-tree-select v-model="formData.dept" node-key="id" :data="options"
					                check-strictly filterable clearable :render-after-expand="false"
					                :props="{label:'name',value: 'id'}"
					                style="width: 100%" placeholder="请选择" />
					    </el-form-item> -->
					    <el-form-item label="状态：" prop="status">
					        <el-switch
					            v-model="formData.status"
					            active-color="#13ce66"
					            inactive-color="#ff4949">
					        </el-switch>
					    </el-form-item>
				</view>
                
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {apiSystemUserAdd,apiSystemUserEdit,apiSystemRole,apiSystemDept} from "@/api/api";
    import {addAdminApi,updateAdminApi,resetAdminPwdApi} from "@/api/system";
    import LyDialog from "@/components/dialog/dialog";
    import XEUtils from "xe-utils";
    import {deepClone} from "@/utils/util";
    export default {
        components: {LyDialog},
        emits: ['refreshData'],
        name: "addAdmin",
        data() {
            return {
				edit_type:'',
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                formData:{
					id:0,
                    real_name:'',
                    account:'',
                    password:'',
					phone:"",
                    dept:'',
                    roles:[],
                    status:true,
                },
                rules:{
                    name: [
                        {required: true, message: '请输入管理员名称',trigger: 'blur'}
                    ],
                    roles: [
                        {required: true, message: '请选择角色',trigger: 'blur'}
                    ],
                    // dept: [
                    //     {required: true, message: '请选择部门',trigger: 'blur'}
                    // ],
                    account: [
                        {required: true, message: '请输入管理员登录账号',trigger: 'blur'}
                    ],
                    is_active: [
                        {required: true, message: '请选择是否启用',trigger: 'blur'}
                    ]
                },
                rolelist:[],
                options:[],
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
				
                this.formData = {
					id:0,
                   real_name:'',
                   account:'',
                   password:'',
                   phone:"",
                   dept:'',
                   roles:[],
                   status:true,
                   is_superuser:false
                }
            },
            addAdminFn(item,flag) {
                this.getapiSystemRole()
                this.getapiSystemDept()
                this.loadingTitle=flag
                this.dialogVisible=true
               
				this.edit_type=flag
                // if(item && item.dept) {
                //     item.dept = item.dept.split(" ")
                // }
                if(item){
                    this.formData = deepClone(item)
					// this.formData.is_superuser=JSON.stringify(item.is_superuser)
                }

                this.formData.role = item?item.role:[]
            },
			resetPwd(){
				resetAdminPwdApi({id:this.formData.id,password:this.formData.password}).then(res=>{
					if(res.code==2000){
						 this.$message.success(res.msg)
						  this.handleClose()
						
					}else{
						this.$message.warning(res.msg)
					}
				})
			},
            submitData() {
				if(this.edit_type=="重置密码"){
					this.resetPwd()
					return
				}
				
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
      //                   if(param.roles){
      //                       let role_ids = []
      //                       for(var r in param.roles){
      //                           role_ids.push(param.roles[r].id)
      //                       }
      //                       param.role_ids = role_ids
      //                   }else{
      //                       param.role_ids = []
      //                   }
						// console.log("roles",param.role_ids)
						
                        if(param.dept){
                            if(typeof  param.dept == 'object') {
                                param.dept=param.dept[param.dept.length-1]
                            }
                        }else{
                            param.dept = ''
                        }

                        if(this.formData.nickname=="" || this.formData.nickname== undefined || this.formData.nickname.length<=0 || this.formData.nickname=='""'){
                            param.nickname = this.formData.name
                        }
						if(this.formData.id<=0){
							addAdminApi(param).then(res=>{
								if(res.code==2000){
									 this.$message.success(res.msg)
									 this.handleClose()
									 this.$emit('refreshData')
								}else{
									this.$message.warning(res.msg)
								}
								 this.loadingSave=false
							})
						}else{
							updateAdminApi(param).then(res=>{
								if(res.code==2000){
									 this.$message.success(res.msg)
									 this.handleClose()
									 this.$emit('refreshData')
								}else{
									this.$message.warning(res.msg)
								}
								this.loadingSave=false
							})
						}
						
                        // if(this.formData.id){
                        //     if(param.post.length<1){
                        //         delete param.post
                        //     }
                        //     apiSystemUserEdit(param).then(res=>{
                        //         this.loadingSave=false
                        //         if(res.code ==2000) {
                        //             this.$message.success(res.msg)
                        //             this.handleClose()
                        //             this.$emit('refreshData')
                        //         } else {
                        //             this.$message.warning(res.msg)
                        //         }
                        //     })
                        // }else{
                        //     apiSystemUserAdd(param).then(res=>{
                        //         this.loadingSave=false
                        //         if(res.code ==2000) {
                        //             this.$message.success(res.msg)
                        //             this.handleClose()
                        //             this.$emit('refreshData')
                        //         } else {
                        //             this.$message.warning(res.msg)
                        //         }
                        //     })
                        // }

                    }
                })
            },
            getapiSystemRole(){
                apiSystemRole({page:1,limit:999,status:1}).then(res=>{
                    if(res.code ==2000) {
                        this.rolelist = res.data.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            getapiSystemDept(){
                apiSystemDept({page:1,limit:999}).then(res=>{
                    if(res.code ==2000) {
                        this.options = XEUtils.toArrayTree(res.data.data, { parentKey: 'parent' })
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },


        }
    }
</script>

