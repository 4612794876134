import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadShopListApi = (params={}) =>ajaxGet({url:'shop/list/', params})
// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})
export const updateShopApi = (params={}) =>ajaxPost({url:'shop/update/', params})