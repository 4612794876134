import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
 
  uploadImg,
 
} from "./request";
import { url } from "./url";


export const loadCustomerListApi = (params={}) =>ajaxGet({url:'customer/list', params})
export const manageCustomerStatusApi = (params={}) =>ajaxPost({url:'customer/manage/status', params})
