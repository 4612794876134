<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="580px" @closed="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
			   
        <el-form-item label="区域：" prop="area">
          <el-input v-model="formData.area" readonly></el-input>
        </el-form-item>
		<el-form-item label="服务商：" prop="agent_name">
          <el-input v-model="formData.agent_name" readonly></el-input>
        </el-form-item>
		 <el-form-item label="主花加价：" prop="add_price_zhu_hua">
          <el-input v-model="formData.add_price_zhu_hua"></el-input>
        </el-form-item>
        <el-form-item label="配花加价：" prop="add_price_pei_hua">
          <el-input v-model="formData.add_price_pei_hua"></el-input>
        </el-form-item> 
		 <el-form-item v-if="formData.id>0" label="加价区/县：" prop="county">
           <el-input v-model="formData.county" readonly></el-input>
        </el-form-item>
		
		<el-form-item label="加价区/县：" v-if="formData.id<1" >
			<!-- @change="handleChange" -->
			<el-select v-model="formData.province_code" placeholder="省" @change="provinceChange"
				style="width: 120px;">
				<el-option v-for="item in province_list" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<el-select v-model="formData.city_code" placeholder="市" @change="cityChange" style="width: 130px;">
				<el-option v-for="item in city_list" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<el-select multiple collapse-tags v-model="formData.county_code_list" placeholder="区/县"
				@change="countyChange" style="width: 195px;">
				<el-option v-for="item in county_list" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
			<!-- <el-cascader style="width: 100%" :show-all-levels="false" :options="agent_area_list" v-model="formData.city_code_list"
		          
		           @change="handleChange"
		           :props="{multiple:true, label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
		           filterable></el-cascader> -->
		</el-form-item>
		
        <!-- <el-form-item v-if="formData.id<1"  label="城市：" prop="adcode">
          <el-cascader style="width: 100%" :show-all-levels="false" :options="areaList" v-model="formData.city_code"
                       @change="handleChange"
                       :props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
                       filterable></el-cascader>
        </el-form-item> -->
		
        <el-form-item label="  ">
           <el-tag v-if="formData.id>0">本次修改只对所选的加价区/县有效</el-tag>
           <el-tag v-if="formData.id<1">本次添加只对所选的加价区/县有效</el-tag>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {AreaPriceAddApi, AreaPriceEditApi, getAllAreaslist} from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"

export default {
  name: "addAreaPrice",
  components: {LyDialog},
  emits: ['refreshData', 'closed'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
		id:0,
		area:"",
		agent_name:"",
        add_price_zhu_hua: '',
        add_price_pei_hua: '',
		province:'',
		province_code:'',
		city:'',
		city_code:'',
		county:'',
		county_code:'',
		county_code_list:[],
		
        adcode: '',
        area: '',
      },
      rules: {
        add_price_zhu_hua: [
          {required: true, message: '请输入主花加价', trigger: 'blur'}
        ], add_price_pei_hua: [
          {required: true, message: '请输入配花加价', trigger: 'blur'}
        ],
        city_code: [
          {required: true, message: '请选择城市', trigger: 'blur'}
        ],
        // is_active: [
        //     { required: true, message: '请选择是否启用', trigger: 'blur' }
        // ]
      },
      all_area_list: [],
	  province_list:[],
	  city_list:[],
	  
	  county_list:[]
    }
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    getAllAreaslistData() {
      getAllAreaslist({subdistrict: 3}).then(res => {
		  
        if (res.code === 2000) {
            this.all_area_list = res.data.data
			
			let area_list = deepClone(this.all_area_list)
			
			if (area_list) {
				area_list.forEach(province => {
					let pro = province
					pro.childlist = []
			      
					this.province_list.push(pro)
			
				})
				this.provinceChange(this.formData.province_code)
				this.cityChange(this.formData.city_code)
			
			}
			
        }
      })
    },
	
	provinceChange(pro_code) {
	
		this.all_area_list.forEach(pro => {
	
			if (pro.id == pro_code) {
				this.formData.province = pro.name
				this.formData.province_code = pro.id
				this.city_list = pro.childlist
			}
		})
	},
	cityChange(city_code) {
	
				this.city_list.forEach(city => {
					if (city.id == city_code) {
						this.formData.city = city.name
						this.formData.city_code = city.id
						this.county_list = [{
							id: "0",
							name: '所有辖区'
						}]
						this.county_list.push(...city.childlist)
	
						this.express_county_list = []
						this.express_county_list.push(...city.childlist)
					}
				})
			
		
		// this.getAddPrice()
	},
	countyChange(county_code) {
		if (county_code == "0") {
			this.county_list.forEach(county => {
				this.formData.county_code_list.push(county.id)
			})
	
		} else {
			county_code.forEach(county => {
				if (county == "0") {
					this.formData.county_code_list = []
					this.county_list.forEach(county => {
						this.formData.county_code_list.push(county.id)
					})
				}
			})
		}
	
	},
    handleChange(e) {
      this.areaList.forEach(item => {
        if (!!item.childlist && item.childlist.length > 0) {
          item.childlist.forEach(itemn => {
            if (itemn.id == e) {
				this.formData.province=item.name
				this.formData.province_code=item.id
				this.formData.city=itemn.name
				this.formData.city_code=itemn.id
              this.formData.area = item.name + itemn.name
            }
          })
        }
      })
    },
    handleOpen(item, flag) {
		this.getAllAreaslistData()
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        delete this.rules.password
        this.formData = deepClone(item)
		
		this.formData.area=this.formData.province+this.formData.city+this.formData.county
		this.formData.agent_name=this.formData.name
		if(!this.formData.agent_name){
			this.formData.agent_name="未开通服务商"
		}
      } else {
        // this.rules.password = [
        //     { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
      }
      
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (this.formData.id>0) {
			
            AreaPriceEditApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.dialogVisible = false;
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
			let county_code_list=this.formData.county_code_list.filter((county_code)=>{
				return county_code!="0"
			})
			
			param.county_code_list=county_code_list  
			  
            AreaPriceAddApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

