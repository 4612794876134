<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="物流公司：" prop="express_name">
			
				<el-select v-if="formData.act_type=='agent'" v-model="formData.express_code" placeholder="请选择" @change="handleAgentChange" filterable
				           clearable style="width: 100%;">
				  <el-option v-for="item in express_list" :key="item.id" :label="item.name" :value="item.code">
				  </el-option>
				</el-select>
				
				<el-select v-if="formData.act_type=='normal'" v-model="formData.rule_id" placeholder="请选择" @change="handleChange" filterable
				           clearable style="width: 100%;">
				  <el-option v-for="item in delivery_rule_list" :key="item.id+100" :label="item.label" :value="item.rule_id">
				  </el-option>
				</el-select>
				
        </el-form-item>
        <!-- <el-form-item label="物流公司：" prop="express_company" v-show="false">
          <el-input v-model.trim="formData.express_company"></el-input>
        </el-form-item> -->
        <el-form-item label="物流单号：" prop="express_no_list">
          <el-input v-model.trim="formData.express_no_list"></el-input>
        </el-form-item>  
		<el-form-item label="实际重量：" prop="real_weight">
          <el-input v-model.trim="formData.real_weight" :readonly></el-input>
        </el-form-item>
		<el-form-item v-if="formData.act_type=='normal'" label="已付运费：" prop="pay_postage">
		  <el-input v-model.trim="formData.pay_postage" readonly></el-input>
		</el-form-item>
		<el-form-item label="实际运费：" prop="compute_postage">
          <el-input v-model.trim="formData.compute_postage" ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {fmallOrderSendgoods, NormalExpressList,loadOrdersExpressListApi,confirmSendApi} from "@/api/api";
import {loadExpressListApi,newDeliveryRuleFeeApi} from'@/api/express'
import LyDialog from "@/components/dialog/dialog";
import {ElLoading} from "element-plus";
import {nextTick} from "vue";
import {deepClone} from "@/utils/util"

export default {
  name: "moduleDeliverGoodsManual",
  components: {LyDialog},
  emits: ['refreshData', 'closed'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
	  ori_express_code:'',
      formData: {
		rule_id:0,
		express_code:"",
		express_name:"",
        ids: [],
        logistics_company_code: '',
        logistics_company: '',
        express_no_list: '',
        manual: 1,//手动发货
		real_postage:0,
		compute_postage:0,
		act_type:''
      },
      rules: {
        // logistics_company_code: [
        //     { required: true, message: '请选择物流公司', trigger: 'blur' }
        // ],
        // logistics_id: [
        //     { required: true, message: '请输入物流单号', trigger: 'blur' }
        // ],
      },
	  express_list:[],
	  delivery_rule_list:[],
      wuliuList: [
        // { id: 'zhongtong', name: '中通快递' },
        // { id: 'yuantong', name: '圆通快递' },
        // { id: 'huitongkuaidi', name: '百世快递' },
        // { id: 'yunda', name: '韵达快递' },
        // { id: 'youzhengguonei', name: '邮政快递包裹' },
        // { id: 'youzhengbk', name: '邮政标准快递' },
        // { id: 'jd', name: '京东快递' },
        // { id: 'shunfeng', name: '顺丰快递' },
        // { id: 'jtexpress', name: '极兔速递' },
        // { id: 'shentong', name: '申通快递' },
        // { id: 'ems', name: 'EMS' },
      ]
    }
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    handleOpen(ori_item, flag,act_type) {
	
	  let item=deepClone(ori_item)
      this.loadingTitle = flag
      this.dialogVisible = true
      this.formData.ids = []
	  this.formData.act_type=act_type
	  
	   this.formData.id=item.id
	   this.formData.real_weight=item.real_weight
	  
	  if (act_type=="agent"){
		   this.loadingTitle=ori_item.agent_name+"--" +this.loadingTitle
	  }
	  if(act_type=="normal"){
		 
		  this.formData.compute_postage=item.compute_postage
		  this.formData.rule_id=item.rule_id
		  this.formData.pay_postage=item.pay_postage
	  }
	  
	  
	  this.old_express_code=item.express_code
	  
	  this.formData.express_code=item.express_code
	  this.formData.express_name=item.express_name
	  
	   this.getExpressList()
      // rows.forEach(item => {
      //   if (item.status == 4) {
      //     this.formData.ids.push(item.id)
      //   }
      // })
	  
      // nextTick(() => {
      //   this.normalExpressList().then(() => {
      //     if (item.logistics_company_code) {
      //       this.wuliuList.forEach((aa) => {
      //         if (aa.id === item.logistics_company_code) {
      //           this.formData.logistics_company = aa.name;
      //           this.formData.logistics_company_code = aa.id;
      //         }
      //       })
      //     }
      //   })
      // })

    },
	getExpressList(){
		if( this.formData.act_type=="agent"){
			loadExpressListApi({status:1,page:1,limit:199}).then(res=>{
				if(res.code==2000){
					this.express_list=res.data.data.data_list
					if (this.express_list.length>0){
						this.express_list.forEach(item=>{
							if(item.name==this.formData.express_name){
								this.formData.express_code=item.code
							}
						})
					}
					
				}
			})
		}else{
			loadOrdersExpressListApi({id:this.formData.id}).then(res=>{
				
				if(res.code==2000){
					
					this.delivery_rule_list=res.data.data
					// if(this.delivery_rule_list.length>0){
					// 	this.delivery_rule_list.forEach(item=>{
					// 		if(item.express_name==this.formData.express_name && item.express_code==this.formData.express_code){
					// 			this.formData.rule_id=item.rule_id
					// 		}
					// 	})
					// }
				}
			})
		}
		
		
	},
	handleAgentChange(e){
	
		 this.express_list.forEach(item=>{
			 if(item.code==e){
				
				 this.formData.express_name=item.name
				 this.formData.express_code=item.code
			 }
		 })
		
	},
	
    handleChange(e) {
		this.delivery_rule_list.forEach(item=>{
			if(item.rule_id==e){
				this.formData.express_name=item.express_name
				this.formData.express_code=item.express_code
				this.formData.rule_id=item.rule_id
				this.getNewDeliveryFee()
			}
		})
      // this.formData.logistics_company = this.wuliuList.filter(item => item.id == e)[0].name
    },
	getNewDeliveryFee(){
		newDeliveryRuleFeeApi({parent_id:this.formData.id,rule_id:this.formData.rule_id}).then(res=>{
			if(res.code==2000){
				
				this.formData.compute_postage=res.data.data.compute_postage
			}else{
				this.formData.compute_postage=0
				this.$confirm(res.msg, '警告', {
				  showCancelButton: false,
				  closeOnClickModal: false,
				  type: 'warning'
				}).then(ret => {
				  vm.$emit('refreshData')
				}).catch(() => {
				
				})
				
				// this.$message(res.msg)
			}
		})
	},
	
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
		 
          param.ids = JSON.stringify(param.ids)
		  param.real_postage=this.formData.compute_postage
          confirmSendApi(param).then(res => {
            this.loadingSave = false
            if (res.code == 2000) {
              this.$message.success("发货成功")
              this.handleClose()
              this.$emit('refreshData')
            } else {
              let vm = this
              this.$confirm(res.msg, '警告', {
                showCancelButton: false,
                closeOnClickModal: false,
                type: 'warning'
              }).then(ret => {
                vm.$emit('refreshData')
              }).catch(() => {

              })

            }
          })

        }
      })
    },
    async normalExpressList() {
      const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        parent: this.$refs['rulesForm'].$el,
      })
      try {
        const response = await NormalExpressList()
        this.wuliuList = response.data.map(item => ({id: item.code, name: item.name}))
      } catch (e) { /* empty */
      } finally {
        loading.close()
      }
    }
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

