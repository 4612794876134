<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="640px" top="10px" @closed="handleClose"
            :fullscreen="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
				<div v-if="act_type=='update'">
					
					<el-form-item label="店铺名称：" prop="shop_name">
					    {{ formData.shop_name }}
					</el-form-item>
					<el-form-item label="店铺分类：" prop="shop_type_name">
					    {{ formData.shop_type_name }}
					</el-form-item> 
					<el-form-item label="联系人：" prop="contact_name">
					    {{ formData.contact_name }}
					</el-form-item>
					<el-form-item label="联系电话：" prop="contact_mobile">
					    {{ formData.contact_phone }}
					</el-form-item>
					<el-form-item label="供应品种：" prop="category_id_list">
					    <el-select v-model="formData.category_id_list" multiple placeholder="请选择">
					        <el-option
					          v-for="item in category_list"
					          :key="item.id"
					          :label="item.name"
					          :value="item.id">
					        </el-option>
					      </el-select>
					</el-form-item>
					
					<el-form-item label="集货站：" prop="station_name">
						<el-select v-model="formData.station_id" placeholder="请选择">
						    <el-option
						      v-for="item in station_list"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					  <!-- <el-input v-model="formData.station_id"></el-input> -->
					</el-form-item>
					<el-button @click="handleClose" :loading="loadingSave">取消</el-button>
					<el-button type="primary" @click="submitData" :loading="loadingSave" >确定</el-button>
					
				</div>
				<div v-else>
					<el-form-item label="店铺名称：" prop="shop_name">
					    {{ formData.shop_name }}
					</el-form-item>
					<el-form-item label="店铺分类：" prop="shop_type_name">
					    {{ formData.shop_type_name }}
					</el-form-item>  
					<el-form-item label="供应品种：" prop="category_id_list">
					    <el-select v-model="formData.category_id_list" multiple placeholder="请选择">
					        <el-option
					          v-for="item in category_list"
					          :key="item.id"
					          :label="item.name"
					          :value="item.id">
					        </el-option>
					      </el-select>
					</el-form-item>
					 <!-- <el-form-item label="供应品类：" prop="category_name">
					    {{ formData.category_name }}
					</el-form-item> -->
					<el-form-item label="联系人：" prop="contact_name">
					    {{ formData.contact_name }}
					</el-form-item>
					<el-form-item label="联系电话：" prop="contact_mobile">
					    {{ formData.contact_phone }}
					</el-form-item>
					<!-- <el-form-item label="地址：" prop="address_detail">
					    {{ formData.areaname }}
					</el-form-item> -->
					<el-form-item label="详细地址：" prop="address_detail">
					    {{ formData.address_detail }}
					</el-form-item>
					<el-form-item label="简介：" prop="desc">
					    <el-input type="textarea" :disabled="true" v-model.trim="formData.desc"
					        :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
					</el-form-item>
					<el-form-item label="头像：" prop="avatar">
					    <el-image preview-teleported :src="formData.avatar" :preview-src-list="[formData.avatar]"
					        style="width: 100px;height: 100px"></el-image>
					</el-form-item>
					<el-form-item label="实力展示：" prop="imgs">
					    <el-image v-for="item in formData.imgs" preview-teleported :src="item" :preview-src-list="[item]"
					        v-bind:key="item.id" style="width: 100px;height: 100px"></el-image>
					</el-form-item>
					
					<el-form-item label="集货站：" prop="station_name">
						<el-select v-model="formData.station_id" placeholder="请选择">
						    <el-option
						      v-for="item in station_list"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						  </el-select>
					  <!-- <el-input v-model="formData.station_id"></el-input> -->
					</el-form-item>
					
					<el-form-item label="审核：" prop="action">
					    <el-radio-group v-model="formData.action" :disabled="!isaudit">
					        <el-radio :label="20">同意</el-radio>
					        <el-radio :label="30">拒绝</el-radio>
					    </el-radio-group>
					</el-form-item>
					<el-form-item label="未通过原因：" prop="audit_remarks">
					    <el-input :disabled="!isaudit" type="textarea" v-model.trim="formData.audit_remarks" clearable
					        placeholder="请输入" style="width:100%"></el-input>
					</el-form-item>
				</div>
				
               
            </el-form>
			
			<template #footer v-if='act_type!="update"'>
			    <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
			    <el-button type="primary" @click="submitData" :loading="loadingSave" v-if="isaudit">确定</el-button>
			</template>
           
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { fmallShopAudit } from "@/api/api"
import { auditShopStatusApi,updateShopApi } from "@/api/shop"
import { loadStationsListApi } from "@/api/stations"
import { loadCategoyListApi } from "@/api/category"
export default {
    emits: ['refreshData', 'closed'],
    name: "addModuleShop",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            peopleList: [],
			act_type:0,
            formData: {
				id:0,
                action: 10,
                audit_remarks: "",
				station_id:0,
				category_name:'',
				category_id_list:[],
            },
            rules: {
				
            },
            isaudit: false,
			station_list:[],
			category_list:[],
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag, isaudit) {
			this.loadStations()
			this.loadCategory()
            this.loadingTitle = flag
            this.dialogVisible = true
            this.isaudit = isaudit
			if(flag=='修改'){
				this.act_type='update'
			}
			if(flag=='审核'){
				this.act_type='audit'
			}
			if(flag=='查看'){
				this.act_type='detail'
			}
            if (item) {
				
                this.formData = deepClone(item)
				
				// this.formData.category_name=item.category_dict.name
                if (this.formData.audit_status == 20 || this.formData.audit_status == 30) {
                    this.formData.action = this.formData.audit_status
                } else {
                    this.formData.action = 20
                }
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
					if(this.act_type=='update'){
						this.loadingSave = true
						let netData = {
						    'id': this.formData.id,
							'station_id':this.formData.station_id,
							'category_id_list':this.formData.category_id_list
						}
						
						updateShopApi(netData).then(res => {
						    this.loadingSave = false
						    if (res.code == 2000) {
						        this.$message.success(res.msg)
						        this.handleClose()
						        this.$emit('refreshData')
						    } else {
						        this.$message.warning(res.msg)
						    }
						})
					}
                    if (this.isaudit) {
                        this.loadingSave = true
                        let netData = {
                            'action': this.formData.action,
                            'audit_remarks': this.formData.audit_remarks,
                            'id': this.formData.id,
							'station_id':this.formData.station_id,
							
                        }
                        auditShopStatusApi(netData).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },
		loadStations(){
			loadStationsListApi({status:true}).then(res=>{
				if(res.code==2000){
					this.station_list=res.data.data
				}
			})
		},
		loadCategory(){
			loadCategoyListApi({status:true}).then(res=>{
				if(res.code==2000){
					
					this.category_list=res.data.data.data_list
					
				}
			})
		}

    }
}
</script>

