import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadSuperAgentListApi = (params={}) =>ajaxGet({url:'super-agent/list', params})
export const operateSuperAgentAPi = (params={}) =>ajaxPost({url:'agent/operate/super-agent', params})

