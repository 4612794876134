<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
              
                <el-form-item label="真实姓名：" prop="real_name">
                    <el-input v-model="formData.real_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="phone">
                    <el-input v-model="formData.phone"></el-input>
                </el-form-item>
				<el-form-item label="商家登录密码：" prop="password">
                    <el-input v-model="formData.password"></el-input>
                </el-form-item>
				<el-form-item label="店铺名称：" prop="shop_name">
				    <el-input v-model="formData.shop_name"></el-input>
				</el-form-item>
			<!-- 	<el-form-item label="店铺图片：">
				    <LyUploadAvatar v-model="formData.shop_cover" width="80px" height="80px"></LyUploadAvatar>
				</el-form-item> -->
				
              
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { UsersUsersAdd, UsersUsersEdit } from "@/api/api";

import { operateSuperAgentAPi } from '@/api/super_agent.js'
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
    name: "addSuperAgent",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            formData: {
				agent_id:0,
				shop_id:0,
                real_name: '',
                phone: '',
				password:'',
                shop_name:'',
				shop_cover:'',
				province:'',
				province_code:'',
				
				city:'',
				city_code:'',
				county:'',
				county_code:'',
				address_detail:''
               
            },
            rules: {
                real_name: [
                    { required: true, message: '请输入店铺联系人真实姓名', trigger: 'blur' }
                ],
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入店铺联系人电话', trigger: 'blur' }
                ],
                shop_name: [
                    { required: true, message: '请填写商家店铺名称', trigger: 'blur' }
                ]
            },
            rolelist: [],
            options: [],
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
			
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData.agent_id=item.agent_id
                this.formData.real_name = item.real_name
				this.formData.phone=item.phone
				
            } 
        },
        submitData() {
			let that=this
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
					param.act_type="open"
					let msg="确定开通超级代理权限吗"
					this.$confirm(msg, {
					    closeOnClickModal: false
					}).then(res => {
					   operateSuperAgentAPi(param).then(res=>{
						   this.loadingSave=false
						   if(res.code==2000){
							  this.dialogVisible=false
							  this.$emit('refreshData')
						   }else{
							   this.$message(res.msg)
						   }
					   })
					}).catch(() => {
					   console.log("cancel")
					})
					
                    // if (this.formData.id) {
                    //     UsersUsersEdit(param).then(res => {
                    //         this.loadingSave = false
                    //         if (res.code == 2000) {
                    //             this.$message.success(res.msg)
                    //             this.dialogVisible = false;
                    //             this.handleClose()
                    //             this.$emit('refreshData')
                    //         } else {
                    //             this.$message.warning(res.msg)
                    //         }
                    //     })
                    // } else {
                    //     UsersUsersAdd(param).then(res => {
                    //         this.loadingSave = false
                    //         if (res.code == 2000) {
                    //             this.$message.success(res.msg)
                    //             this.handleClose()
                    //             this.$emit('refreshData')
                    //         } else {
                    //             this.$message.warning(res.msg)
                    //         }
                    //     })
                    // }

                }
            })
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

