import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadStationsListApi = (params={}) =>ajaxGet({url:'mall/stations/list/', params})
export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
export const manageStationsStatusApi = (params={}) =>ajaxPost({url:'mall/stations/manage/status/', params})