<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="80%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    订单信息
                </div>
                <el-table border :data="data_list">
                     <el-table-column type="selection" width="55" align="center"></el-table-column>
                     <el-table-column min-width="200" prop="order_id" label="订单编号"></el-table-column>
                     <el-table-column width="100" prop="goods_no" label="货架号"></el-table-column>
                     <!-- <el-table-column min-width="110" prop="customer_nick_name" label="收货人"></el-table-column> -->
                     
                     <el-table-column min-width="110" prop="address_name" label="收货人"></el-table-column>
                     <el-table-column min-width="110" prop="address_phone" label="手机号"></el-table-column>
                     <el-table-column min-width="110" prop="total_orders_money" label="订单总金额"></el-table-column>
                     <el-table-column min-width="110" prop="status_name" label="订单状态"></el-table-column>
                     <!-- <el-table-column min-width="110" prop="username" label="售后状态"></el-table-column> -->
                     <!-- <el-table-column min-width="110" prop="ruwei_type_name" label="入位类型"></el-table-column> -->
                     <!-- <el-table-column min-width="110" prop="username" label="所属商家"></el-table-column> -->
                     <!-- <el-table-column width="120" prop="agent_name" label="所属代理"></el-table-column> -->
                     <el-table-column min-width="170" prop="pay_time" label="下单时间"></el-table-column>
                     <el-table-column width="120" prop="express_company" fixed="right" label="物流"></el-table-column>
					 <el-table-column label="操作" fixed="right" width="280">
					 	<template #header>
					 		<div style="display: flex;justify-content: space-between;align-items: center;">
					 			<div>操作</div>
					 			<div @click="setFull">
					 				<el-tooltip content="全屏" placement="bottom">
					 					<el-icon>
					 						<full-screen />
					 					</el-icon>
					 				</el-tooltip>
					 			</div>
					 		</div>
					 	</template>
					 	<template #default="scope">
					 		<span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
					 			v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>

					
					 	</template>
					 </el-table-column>
                   
                </el-table>
                
                
                <el-row :gutter="20" v-if="formData.logistics_id">
                    <el-col :span="6">
                        <el-form-item label="物流公司：">
                            {{ formData.logistics_company }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="物流单号：">
                            {{ formData.logistics_id }}<el-button style="margin-left: 14px;" type="primary" size="small"
                                @click="getLogisticsSearch" :loading="loadingLogistics">查看物流</el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="物流费用：">
                            {{ formData.logistics_fee }}
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="6" v-if="formData.logistics_id">
                        <el-form-item label="包装费用：">
                            {{ formData.logistics_pkg_fee }}
                        </el-form-item>
                    </el-col> -->
                </el-row>
               

            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderDetail, orderLogisticsSearch,getOrdersDetailApi } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { getStorage } from "@/utils/util"
export default {
    name: "agentOrderDetail",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingLogistics: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: 0,
			agent_id:0,
			order_date:'',
			act_type:'',
			data_list:[],
			page:1,
			limit:1000,
            formData: {
                payinfo: {
                    payinfo: {},
                    user_refund_info: {},
                    user_freight_refund_info: {},
                    user_lackgoods_refund_info: {},
                    user_freight_pay_info: {},
                },
                commentinfo: [],
                goods_info: [],
                logistics_id: "",
                logistics_company: "",
                logistics_company_code: "",
				
            },
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
            identity: getStorage('identity')
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(id, flag,order_date) {
            this.loadingTitle = flag
            this.dialogVisible = true
			this.order_date=order_date
			this.id=id
			
			this.getOderDetail()
           
        },
        handleEdit(item){
			 // this.$emit('closed')
			 this.$emit('handleDetail',item)
		},
        getOderDetail() {
            getOrdersDetailApi({ id: this.id,act_type:"agent" ,order_date:this.order_date,path:this.page,limit:this.limit}).then(res => {
				
                if (res.code === 2000) {
					this.data_list=res.data.data_list
					this.total=res.data.total
					// console.log("agent_detail:",res.data)
                    // this.formData = res.data
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

