import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
} from "./request";
import { url } from "./url";


export const loadConfigListApi = (params={}) =>ajaxGet({url:'platformsettings/list', params})
export const addGroupApi = (params={}) =>ajaxPost({url:'platformsettings/add/group', params})
export const addConfigApi = (params={}) =>ajaxPost({url:'platformsettings/add/config', params})
export const saveConfigApi = (params={}) =>ajaxPost({url:'platformsettings/save/config', params})
export const manageConfigStatusApi = (params={}) =>ajaxPost({url:'platformsettings/config/manage/status', params})
export const getDetailApi = (params={}) =>ajaxGet({url:'platformsettings/config/detail', params})
// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
// export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
// export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})