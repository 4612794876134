<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" :fullscreen="false" :draggable="false"
            @closed="handleClose">
            <lyDynamicLabelGroup v-model="formData.config" ref="reflabalconfig"></lyDynamicLabelGroup>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallGoodsBreedSaveConfig } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import lyDynamicLabelGroup from "@/components/lyDynamicLabelGroup";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog, lyDynamicLabelGroup },
    emits: ['refreshData', 'closed'],
    name: "addModuleGoodsBreedConfig",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                id: "",
                config: [],
            },
            rules: {
                name: [
                    { required: true, message: '请填写等级名称', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                let tempitem = deepClone(item)
                this.formData.id = tempitem.id
                this.formData.config = !!tempitem.config && tempitem.config.length > 0 ? tempitem.config : []
            }
        },
        async submitData() {
            let result = await this.$refs['reflabalconfig'].submitValidate()
            // console.log(result)
            if (result) {
                this.loadingSave = true
                let param = {
                    ...this.formData
                }
                if (this.formData.id) {
                    fmallGoodsBreedSaveConfig(param).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            }
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

