<template>
    <div class="carouselSettingsimg">
		<div class="tableSelect" ref="tableSelect">
			<el-form :inline="true" :model="formInline" label-position="left">
				<el-form-item label="名称：">
					<el-input size="default" v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称"
						@change="search" style="width:200px"></el-input>
				</el-form-item>
				
				<el-form-item label="归属超级代理：" >
					<el-select v-model="formInline.appid" placeholder="请选择"  style="width: 220px;" filterable >
					    <el-option
					      v-for="item in super_agent_list"
					      :key="item.id"
					      :label="item.name"
					      :value="item.appid">
					    </el-option>
					  </el-select>
				</el-form-item>
			
				<el-form-item label=""><el-button @click="search" type="primary" icon="Search"
						v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
				<el-form-item label=""><el-button @click="handleEdit('', 'reset')"
						icon="Refresh">重置</el-button></el-form-item>
				<el-form-item label=""><el-button size="default" icon="Plus" @click="addModule" type="primary"
						v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
			</el-form>
		</div>
		
        <el-tabs v-model="formInline.pos_type" @tab-click="handleClick">
            <el-tab-pane label="首页轮播" :name="1"></el-tab-pane>
            <el-tab-pane label="首页弹窗" :name="2"></el-tab-pane>
            <el-tab-pane label="首页广告左" :name="3"></el-tab-pane>
            <el-tab-pane label="首页广告右上" :name="4"></el-tab-pane>
            <el-tab-pane label="首页广告右下" :name="5"></el-tab-pane>
            <el-tab-pane label="整件团购" :name="6"></el-tab-pane>
            <el-tab-pane label="优惠券弹窗" :name="7"></el-tab-pane>
        </el-tabs>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
            <el-table-column type="index" width="60" align="center" label="序号"></el-table-column>
            <el-table-column width="120" prop="image" label="图片">
                <template #default="scope">
                    <el-image :src="scope.row.image" style="width: 40px;height: 40px" :preview-src-list="[scope.row.image]"
                        :preview-teleported="true"></el-image>
                </template>
            </el-table-column>
            <el-table-column width="250" prop="title" label="名称"></el-table-column>
            <el-table-column width="250" prop="appid" label="展示范围">
				<template #default="scope">
					<view v-if="scope.row.appid=='0'">
						<el-tag style="color: red;" >全平台（包括所有超代app\小程序端）</el-tag>
					</view>
				    
					<view v-else>
						<el-tag v-if="scope.row.appid=='1'" >集花宝自营平台</el-tag>
						<el-tag v-else >{{scope.row.agent_name}}-超级代理</el-tag>
					</view>
				    
				   
				</template>
			</el-table-column>
            <el-table-column width="120" prop="sort" label="排序"></el-table-column>
            <el-table-column width="150" label="状态">
                <template #default="scope">
                    <el-tag v-if="scope.row.status" @click="manageStatus(scope.row.id, 'stop')">展示中</el-tag>
                    <el-tag v-else type="danger" @click="manageStatus(scope.row.id, 'start')">已停止</el-tag>
                </template>
            </el-table-column>
            <el-table-column width="250" prop="create_datetime" label="创建时间"></el-table-column>
            <el-table-column label="操作" fixed="right" >
                <template #header>
                    <el-button size="small" @click="addModule" type="primary"
                        v-show="isShowBtn('carouselSettingsimg', '轮播图设置', 'Create')"
                        v-if="formInline.type == 1 || formInline.type == 2 || formInline.type == 6">新增</el-button>
                    <span v-else>操作</span>
                </template>
                <template #default="scope">
                    <!--v-show="isShowBtn('dynamicsInfo','平台图片设置','Update')"-->
                    <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                        v-show="isShowBtn('carouselSettingsimg', '轮播图设置', 'Update')">编辑</span>
						
					<span class="table-operate-btn" v-if="scope.row.status" @click="manageStatus(scope.row.id, 'stop')">停止展示</span>
					<span class="table-operate-btn" v-if="!scope.row.status" @click="manageStatus(scope.row.id, 'start')">上架展示</span>
						
                  <!--  <span class="table-operate-btn" @click="manageStatus(scope.row.id,'del')"
                        v-show="isShowBtn('carouselSettingsimg', '轮播图设置', 'Delete')"
                        v-if="formInline.pos_type == 1 || formInline.pos_type == 2 || formInline.pos_type == 6">删除</span> -->
					<span class="table-operate-btn" @click="manageStatus(scope.row.id,'del')" >删除</span>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-module ref="addModuleFlag" @refreshData="getData"></add-module>
    </div>
</template>
<script>
import addModule from "./components/addCarouselModule";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { platformsettingsLunboimg, platformsettingsLunboimgDelete } from '@/api/api'
import { loadBannerListApi,manageBannerStatusApi } from '@/api/banner'
import {loadSuperAgentListApi} from '@/api/super_agent.js'
export default {
    components: {
        Pagination,
        addModule
    },
    name: 'carouselSettingsimg',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                pos_type: 1,// 【参数】type轮播图类型
            },
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '是' },
                { id: 0, name: '否' }
            ],
            tableData: [],
			super_agent_list:[]
        }
    },
    methods: {
		loadSuperAgent(){
			loadSuperAgentListApi().then(res=>{
				if(res.code==2000){
					this.super_agent_list=res.data
				}
			})
		},
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        handleClick(tab, e) {
            this.formInline.pos_type = tab.props.name
            this.search()
        },
        addModule() {
            this.$refs.addModuleFlag.addModuleFn(null, '新增', 0)
        },
        changeStatus(row) {
            // console.log(row,'row----')
        },
		manageStatus(id,act){
			let msg=''
			if(act=='stop'){
				msg="确定停止展示该轮播图吗？"
			}
			if(act=='start'){
				msg='确定在用户端展示该轮播图吗？'
			}
			if(act=='del'){
				msg='确定删除该轮播图吗？'
			}
			
			this.$confirm(msg, {
			    closeOnClickModal: false
			}).then(ret => {
			    manageBannerStatusApi({ id: id,act:act}).then(res => {
			        if (res.code == 2000) {
			            this.$message.success(res.msg)
			            this.search()
			        } else {
			            this.$message.warning(res.msg)
			        }
			    })
			}).catch(err => {
			   console.log(err)
			})
		},
        handleEdit(row, flag) {
            let vm = this
			if(flag=='reset'){
				this.formInline.name=''
				return
			}
            if (flag == 'edit') {
                vm.$refs.addModuleFlag.addModuleFn(row, '编辑', this.formInline.pos_type)
            }
            if (flag == 'delete') {
                vm.$confirm('您确定要删除选中的内容？', {
                    closeOnClickModal: false
                }).then(ret => {
                    platformsettingsLunboimgDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
        },

        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            loadBannerListApi(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = 43
            tabSelectHeight = this.isFull ? tabSelectHeight - 100 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }
    },
    created() {
		this.loadSuperAgent()
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },
    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
<style scoped>
.carouselSettingsimg .el-tabs {
    background: var(--el-bg-color);
}

.carouselSettingsimg:deep(.el-tabs) .el-tabs__nav .is-top {
    padding-left: 10px;
}
</style>

