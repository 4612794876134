<template>
  <div :class="{ 'ly-is-full': isFull }">
    <div class="tableSelect" ref="tableSelect">
		
      <el-form :inline="true" :model="formInline" label-position="left">
		  <el-form-item label="归属超级代理：" >
		  	<el-select v-model="formInline.appid" placeholder="请选择"  style="width: 220px;" filterable @change="search">
		  	    <el-option
		  	      v-for="item in super_agent_list"
		  	      :key="item.id"
		  	      :label="item.name"
		  	      :value="item.appid">
		  	    </el-option>
		  	  </el-select>
		  </el-form-item>
		  
		  <el-form-item label="加价类型：" >
		  	<el-select v-model="formInline.add_price_type_id" placeholder="请选择"  style="width: 220px;" filterable @change="search">
		  	    <el-option
		  	      v-for="item in add_price_type_list"
		  	      :key="item.id"
		  	      :label="item.name"
		  	      :value="item.id">
		  	    </el-option>
		  	  </el-select>
		  </el-form-item>
		  
		 
		<el-form-item label=""><el-button @click="search" type="primary" icon="Search"
		        v-show="hasPermission(this.$route.name, 'Search')">查询</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                                          icon="Refresh">重置</el-button></el-form-item>
        <el-form-item label=""><el-button @click="handelAddClick" type="primary" icon="Plus"
                                          v-show="hasPermission(this.$route.name, 'Create')">新增</el-button></el-form-item>
      </el-form>
    </div>
    <div>
		<p>加价顺序优先级：品种>分类>等级，如果某商品归属的品种、分类以及等级都设置了加价，则只取品种加价，如果只设置了分类、等级加价，则只取分类加价，以此类推</p>
	</div>
    <div class="table" style="margin-top: 10px;">
      <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
        <el-table-column  width="60" align="center" label="序号" prop="id">
            <!-- <template #default="scope">
                <span v-text="getIndex(scope.$index)"></span>
            </template> -->
        </el-table-column>
        <el-table-column width="150" prop="add_price_type_name" label="加价类型"></el-table-column>
        <el-table-column v-if="formInline.add_price_type_id==0" width="150" prop="flower_type_name" label="花型"></el-table-column>
        <el-table-column v-if="formInline.add_price_type_id==0" width="120" prop="level" label="等级"></el-table-column>
        <el-table-column v-if="formInline.add_price_type_id>0" width="120" prop="category_name" label="分类"></el-table-column>
        <el-table-column v-if="formInline.add_price_type_id>1" width="120" prop="breeds_name" label="品种">
			
		</el-table-column>
        <el-table-column width="150" prop="add_price" label="加价"></el-table-column>
        <el-table-column width="150"  label="所属超代">
			<template #default="scope">
						  <el-tag v-if="scope.row.appid!='1'">{{scope.row.agent_name}}</el-tag>
						  <el-tag v-if="scope.row.appid=='1'">集花宝平台自营</el-tag>
						  
			  <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
			             @change="changeStatus(scope.row)">
			  </el-switch> -->
			</template>
		</el-table-column>
        <el-table-column width="150" label="使用状态">
          <template #default="scope">
			  <el-button v-if="scope.row.status" type="primary" round size="small" @click="mangeStatus(scope.row.id,'stop')">使用中</el-button>
			  <el-button v-if="!scope.row.status" type="warning" round size="small" @click="mangeStatus(scope.row.id,'start')">未使用</el-button>
            <!-- <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                       @change="changeStatus(scope.row)">
            </el-switch> -->
          </template>
        </el-table-column>
		 <el-table-column width="350" prop="mark" label="备注"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template #header>
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div>操作</div>
            </div>
          </template>
          <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                              v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
            <span class="table-operate-btn" @click="mangeStatus(scope.row.id,'del')"
                  v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    <addDialog ref="addAreaPriceFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
    </addDialog> 
  </div>
</template>
<script>
import addDialog from "./components/addPlatformAddPrice";
import Pagination from "@/components/Pagination";
import {dateFormats, getTableHeight, hasPermission} from "@/utils/util";

import {loadPlatAddPriceListApi,manageStatusApi} from'@/api/platform_add_price.js'
import {loadSuperAgentListApi} from '@/api/super_agent.js'
export default {
  components: {
    Pagination,
    addDialog
  },
  name: 'paltformAddPriceManager',
  data() {
    return {
      isFull: false,
      tableHeight: 500,
      loadingPage: false,
      formInline: {
		add_price_type_id:0,
		appid:"1",
        page: 1,
        limit: 10,
      },
      pageparm: {
        page: 1,
        limit: 10,
        total: 0
      },
      statusList: [
        { id: 1, name: '正常' },
        { id: 0, name: '禁用' }
      ],
      identityList: [
        { id: 0, name: '普通用户' },
        { id: 1, name: '会员' },
      ],
      timers: [],
      tableData: [],
      isDialogVisible: false,
	  super_agent_list:[],
	  add_price_type_list:[{id:0,name:'按等级加价'},{id:1,name:"按分类加价"},{id:2,name:'按品种加价'}]
    }
  },

  methods: {
    hasPermission,
    // 表格序列号
    getIndex($index) {
      // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
      return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
    },
    setFull() {
      this.isFull = !this.isFull
      window.dispatchEvent(new Event('resize'))
    },
	mangeStatus(id,act){
		let msg=""
		if(act=='start'){
			msg='确定启用该加价吗'
		}
		if(act=='stop'){
			msg='确定停用该加价吗？'
		}
		
		this.$confirm(msg,'提醒',{
			closeOnClickModal: false,
			type: "warning"
		}).then(res=>
		{
			manageStatusApi({ id: id,act_type:act }).then(res => {
			  if (res.code == 2000) {
			    this.$message.success(res.msg)
			    this.getData()
			  } else {
			    this.$message.warning(res.msg)
			  }
			})
		})
	},
    
    handelAddClick() {
      this.isDialogVisible = true
      this.$nextTick(() => {
        this.$refs.addAreaPriceFlag.handleOpen(null, "新增")
      })
    },
    handleEdit(row, flag) {
      if (flag == 'edit') {
        this.isDialogVisible = true
        this.$nextTick(() => {
          this.$refs.addAreaPriceFlag.handleOpen(row, "编辑")
        })
      }
      else if (flag == 'disable') {
        let vm = this
		let act=''
		if(row.status){
			act='stop'
		}else{
			act='start'
		}
        AreaPriceManageStatusApi({ id: row.id ,act:act }).then(res => {
          if (res.code == 2000) {
            vm.$message.success(res.msg)
            vm.search()
          } else {
            vm.$message.warning(res.msg)
          }
        })
      }
      else if (flag == 'delete') {
        let vm = this
        vm.$confirm('您确定要删除选中的数据吗？', {
          closeOnClickModal: false
        }).then(res => {
          AreaPriceManageStatusApi({ id: row.id ,act:'del'}).then(res => {
            if (res.code == 2000) {
              vm.$message.success(res.msg)
              vm.search()
            } else {
              vm.$message.warning(res.msg)
            }
          })
        }).catch(() => {

        })
      }
      else if (flag == "reset") {
        this.formInline = {
          page: 1,
          limit: 10,
		  appid:"1",
        }
        this.pageparm = {
          page: 1,
          limit: 10,
          total: 0,
		  appid:"1"
        }
        this.getData()
      }
    },
    /**
     * 从URL里下载文件
     */
    // 下载文件
    downloadFileURL(url) {
      var iframe = document.createElement("iframe")
      iframe.style.display = "none";
      iframe.src = url;
      document.body.appendChild(iframe);
    },
    exportDataBackend() {
      var params = {
        page: 1,
        limit: 9999,
      }
      // UsersUsersExportexecl(params).then(res => {
      //     if (res.code == 2000) {
      //         this.downloadFileURL(res.data.data)
      //         //this.$message.warning(res.data.data)
      //     }
      // })
    },
    callFather(parm) {
      this.formInline.page = parm.page
      this.formInline.limit = parm.limit
      this.getData()
    },
    search() {
      this.formInline.page = 1
      this.formInline.limit = 10
      this.getData()
    },
	
    //获取列表
    getData() {
      this.loadingPage = true
      loadPlatAddPriceListApi(this.formInline).then(res => {
        this.loadingPage = false
        if (res.code == 2000) {
			let data=res.data
			console.log("data:",data)
          this.tableData =data.data
          this.pageparm.page = data.page;
          this.pageparm.limit = data.limit;
          this.pageparm.total = data.total;
        }
      })
    },

    timeChange(val) {
      if (val) {
        this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
        this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
      } else {
        this.formInline.beginAt = null
        this.formInline.endAt = null
      }
      this.search()
    },
    // 计算搜索栏的高度
    listenResize() {
      this.$nextTick(() => {
        this.getTheTableHeight()
      })
    },
    getTheTableHeight() {
      let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
      tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
      this.tableHeight = getTableHeight(tabSelectHeight)
    },
	loadSuperAgent(){
		loadSuperAgentListApi().then(res=>{
			if(res.code==2000){
				this.super_agent_list=res.data
			}
		})
	},

  },
  created() {
	this.loadSuperAgent()  
    this.getData()
  },
  mounted() {
    // 监听页面宽度变化搜索框的高度
    window.addEventListener('resize', this.listenResize);
    this.$nextTick(() => {
      this.getTheTableHeight()
    })
  },

  unmounted() {
    // 页面销毁，去掉监听事件
    window.removeEventListener("resize", this.listenResize);
  },
}
</script>
