<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="集货站：" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
        <el-form-item label="商家扣费：" prop="shop_fee">
          <el-input v-model="formData.shop_fee"></el-input>
        </el-form-item>
		
        <el-form-item label="城市：" prop="adcode">
          <el-cascader style="width: 100%" :show-all-levels="false" :options="areaList" v-model="formData.city_code"
                       @change="handleChange"
                       :props="{ label: 'name', value: 'id', children: 'childlist', emitPath: false }" clearable
                       filterable></el-cascader>
        </el-form-item>
		<el-form-item label="详细地址：" prop="address_detail">
		  <el-input v-model="formData.address_detail"></el-input>
		</el-form-item>
        <!-- <el-form-item label="状态：" prop="is_active">
            <el-switch v-model="formData.is_active" active-color="#13ce66" inactive-color="#ff4949">
            </el-switch>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
  </div>
</template>

<script>
import {AreaPriceAddApi, AreaPriceEditApi, getAllAreaslist} from "@/api/api";
import {stationsAddApi,stationsEditApi} from "@/api/stations";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"

export default {
  name: "addStations",
  components: {LyDialog},
  emits: ['refreshData', 'closed'],
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      formData: {
		id:0,
        name:'',
		
		province:'',
		province_code:'',
		city:'',
		city_code:'',
		county:'',
		county_code:'',
		shop_fee:0.0,
        adcode: '',
        area: '',
		address_detail:'',
      },
      rules: {
        name: [
          {required: true, message: '请输入集货站名称', trigger: 'blur'}
        ],
		
        city_code: [
          {required: true, message: '请选择集货站所在城市', trigger: 'blur'}
        ],
        // is_active: [
        //     { required: true, message: '请选择是否启用', trigger: 'blur' }
        // ]
      },
      areaList: [],
    }
  },
  methods: {
    handleClose() {
      this.$emit('closed')
    },
    getAllAreaslistData() {
      getAllAreaslist({subdistrict: 1}).then(res => {
        if (res.code === 2000) {
          this.areaList = res.data.data
        }
      })
    },
    handleChange(e) {
      this.areaList.forEach(item => {
        if (!!item.childlist && item.childlist.length > 0) {
          item.childlist.forEach(itemn => {
            if (itemn.id == e) {
				this.formData.province=item.name
				this.formData.province_code=item.id
				this.formData.city=itemn.name
				this.formData.city_code=itemn.id
              this.formData.area = item.name + itemn.name
            }
          })
        }
      })
    },
    handleOpen(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      if (item) {
        delete this.rules.password
        this.formData = deepClone(item)
      } else {
        // this.rules.password = [
        //     { required: true, message: '请输入密码', trigger: 'blur' }
        // ]
      }
      this.getAllAreaslistData()
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (this.formData.id>0) {
            stationsEditApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.dialogVisible = false;
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            stationsAddApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
  }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}
</style>

