<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                
                <el-form-item label="修改类型：" prop="reason_id">
                   <el-select v-model="formData.reason_id" placeholder="请选择" @change="handleChange"
                               style="width: 100%;">
                     <el-option v-for="item in reason_list" :key="item.id" :label="item.name" :value="item.id" >
                     </el-option>
                   </el-select>
                </el-form-item>
				
				<div v-if="formData.reason_id<3">
					<el-form-item label="缺货数量：" prop="lock_count">
					    <el-input v-model="formData.lock_count"></el-input>
					</el-form-item> 
					<el-form-item v-if="formData.reason_id==2" label="平台补货数量：" prop="platform_count"  >
					    <el-input v-model="formData.platform_count"></el-input>
					</el-form-item>
					<el-form-item v-if="formData.reason_id==2" label="平台补货价格：" prop="platform_price" >
					    <el-input v-model="formData.platform_price"></el-input>
					</el-form-item>
				</div>
                
               
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { UsersUsersAdd, UsersUsersEdit,updateRuWeiApi } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";

export default {
    name: "updateRuWeiDialog",
    components: { LyDialog },
    emits: ['closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
          
			reason_list:[{id:3,name:'正常入位'},{id:1,name:'缺货退款'},{id:2,name:"平台补货"}],
            formData: {
				id:0,
               reason_id:1,
			   lock_count:0,
			   platform_count:0,
			   platform_price:0,
            },
            rules: {
                // lock_count: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
                // nickname: [
                //     {required: true, message: '请输入昵称',trigger: 'blur'}
                // ],
               
            },
            rolelist: [],
            options: [],
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item) {
            this.loadingTitle = "入位修改"
            this.dialogVisible = true
            if (item) {
                
                this.formData.id = item.id
            } 
        },
		handleChange(e){
			console.log("change:",e)
		},
        submitData() {
			
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        updateRuWeiApi(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.dialogVisible = false;
                                this.handleClose()
                                this.$emit('updateData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } 

                }
            })
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

