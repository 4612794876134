<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item label="名称：">
                    <el-input v-model.trim="formInline.name" maxlength="60" clearable placeholder="名称" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="手机号：">
                    <el-input v-model.trim="formInline.mobile" maxlength="60" clearable placeholder="手机号" @change="search"
                        style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="服务地区：">
                    <el-input v-model.trim="formInline.cityname" maxlength="60" clearable placeholder="开通地区"
                        @change="search" style="width:130px"></el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select size="default" v-model="formInline.is_active" placeholder="请选择" clearable style="width: 90px"
                        @change="search">
                        <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="时间：">
                    <el-date-picker style="width:350px" v-model="timers" type="datetimerange" @change="timeChange"
                        range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button @click="search" type="primary" icon="Search"
                       >查询</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handleEdit('', 'reset')"
                        icon="Refresh">重置</el-button></el-form-item>
                <el-form-item label=""><el-button @click="handelAddClick" type="primary" icon="Plus"
                       >新增</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <el-table-column  width="60" align="center" label="序号" prop="id">
                   <!-- <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template> -->
                </el-table-column>
                <el-table-column min-width="50" prop="avatar" label="用户头像">
                    <template #default="scope">
                        <img :src="scope.row.avatar ? scope.row.avatar : defaultImg" style="width: 30px;height: 30px"
                            :onerror="defaultImg">
                    </template>
                </el-table-column>
                <el-table-column min-width="100" prop="phone" label="账号"></el-table-column>
                <el-table-column min-width="120"  label="名称">
					<template #default="scope">
						<el-text >{{scope.row.name}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <!-- <span v-if="scope.row.is_super" style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;">超代</span> -->
					</template>
				</el-table-column>
                <el-table-column min-width="100" prop="phone" label="手机号"></el-table-column>
                <el-table-column min-width="200"  label="开通地区" >
					<template #default="scope">
						<el-text >{{scope.row.area}}</el-text>
						<!-- <el-button size="small" type="primary" style="margin-left: 8px;">详细</el-button> -->
					    <span  style="margin-left: 8px;border: 1px solid #ccc;border-radius: 4px; color:royalblue;" @click="lookAgentArea(scope.row)">详细</span>
					</template>
				</el-table-column>
				<el-table-column width="220"  label="超级代理">
					<template #default="scope">
						<span v-if="scope.row.is_super" style="color: red;">是</span>
						<span v-if="!scope.row.is_super" >否</span>
					    <span v-if="!scope.row.is_super" class="table-operate-btn " style="border: 1px solid #ccc; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'open')"
					       >开通</span>
						<span v-if="scope.row.is_super && scope.row.super_agent.status" class="table-operate-btn" style="border: 1px solid #ff5500; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'stop')"
					       >停用</span>
						<span v-if="scope.row.is_super && !scope.row.super_agent.status" class="table-operate-btn" style="border: 1px solid #ff5500; border-radius: 2px;margin-left: 10px;width: 48px;" @click="handleRenter(scope.row, 'start')"
					       >启用</span>
						<span v-if="scope.row.is_super">{{scope.row.super_agent.appid}}</span>
					    
					</template>
				</el-table-column> 
				
				<el-table-column min-width="100" prop="admin_name" label="归属员工"></el-table-column>
                <el-table-column width="120"  label="重置密码">
					<template #default="scope">
					    <span class="table-operate-btn" @click="handleEdit(scope.row, 'updatePwd')"
					        v-show="hasPermission(this.$route.name, 'Update')">重置密码</span>
					    
					</template>
				</el-table-column>
                <!-- <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column> -->
                <el-table-column width="130" prop="express_name" label="收货物流"></el-table-column>
                <el-table-column min-width="50" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addUser ref="addUserFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addUser>
		<editSuperAgent ref="editSuperAgentRef" v-if="show_edit_super_dialog" @refreshData="getData" @closed="show_edit_super_dialog=false"></editSuperAgent>
		
		<udateAgentPassword ref="updateAgentPwd" @refreshData="getData" v-if="is_show_update_box" @closed="is_show_update_box = false">
        </udateAgentPassword>
		<el-dialog title="服务区域详情" v-model="is_show_agent_area" width="30%" :append-to-body="true" >
			<el-form ref="agentAreaForm" :model="agent_area_form"  label-width="80px">
				<el-form-item label="省/直辖市">
					<el-input v-model="agent_area_form.province" readonly></el-input>
				</el-form-item>
				<el-form-item label="市/州">
					<el-input v-model="agent_area_form.city" readonly></el-input>
				</el-form-item>
				<el-form-item label="区/县">
					<el-input type="textarea" v-model="agent_area_form.county_name" readonly></el-input>
				</el-form-item>
				<el-form-item >
					<el-button type="primary" @click="closeAgentArea">关闭</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		
    </div>
</template>
<script>
import addUser from "./components/addUserAgent";
import editSuperAgent from "./components/editSuperAgent";
import udateAgentPassword from "./components/updateAgentPassword";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersAgentUsers, UsersAgentUsersDelete,manageAgentStatus } from '@/api/api'

import { operateSuperAgentAPi } from '@/api/super_agent.js'
export default {
    components: {
        Pagination,
        addUser,
		udateAgentPassword,
		editSuperAgent
    },
    name: 'agentUserManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
			is_show_update_box:false,
			is_show_agent_area:false,
			agent_area_form:{
				province:"",
				city:"",
				county_name:""
			},
			show_edit_super_dialog:false
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
		closeAgentArea(){
			this.is_show_agent_area=false
		},
		lookAgentArea(item){
			
		    if (item.county_table_list){
				item.county_table_list.forEach(e=>{
					this.agent_area_form.province=e.province
					this.agent_area_form.city=e.city
				})
				
			}
			
			this.agent_area_form.county_name=item.county_name
			this.is_show_agent_area=true
			
		},
        changeStatus(row) {
            let that = this
            let flat = row.is_active
            row.is_active = !row.is_active
            let temp_is_allow = 1
			
			let act=""
			let msg=""
			if(row.status){
				act="start"
				msg="确定启用该代理吗？"
			}else{
				act='stop'
				msg="确定停用该代理吗？停用后该区域用户的订单将不会经过该代理！"
			}
			
			
            that.$confirm(msg, '提醒', {
                closeOnClickModal: false,
                type: "warning"
            }).then(res => {
               
                manageAgentStatus({ id: row.id ,act:act}).then(res => {
                    if (res.code == 2000) {
                        that.$message.success(res.msg)
                        that.getData()
                    } else {
						row.status=!row.status
                        that.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addUserFlag.handleOpen(null, "新增")
            })
        },
        handleEdit(row, flag) {
			if(flag=="updatePwd"){
				this.is_show_update_box = true
				this.$nextTick(() => {
				    this.$refs.updateAgentPwd.handleOpen(row, "重置密码")
				})
			}
			
            if (flag == 'edit') {
                this.isDialogVisible = true
                this.$nextTick(() => {
					
                    this.$refs.addUserFlag.handleOpen(row, "编辑")
                })
            }
            else if (flag == 'disable') {
                let vm = this
                manageAgentStatus({ id: row.id,act:'stop' }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.search()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除该代理吗？删除代理不会影响该地区的区域加价，删除后请注意检查该地区的区域加价是否需要修改', {
                    closeOnClickModal: false
                }).then(res => {
                    manageAgentStatus({ id: row.id ,act:'del'}).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
				
                this.getData()
            }
        },
		handleRenter(item,act_type){
			let msg=""
			if(act_type=="open"){
				msg="确定为"+item.name+"开通超级代理权限嘛？"
				
				this.show_edit_super_dialog=true
				
				let real_info={
					agent_id:item.id,
					real_name:item.real_name,
					phone:item.phone,
					
				}
				this.$nextTick(() => {
				    this.$refs.editSuperAgentRef.handleOpen(real_info, "开通超级代理")
				})
				return
			}
			if(act_type=="close"){
				msg="确定关闭"+item.name+"的超级代理权限吗？关闭后该超级代理名下的用户系统将不能使用！"
			}
			
			if(act_type=="stop"){
				msg="该超级代理权限正常使用中，确定停用"+item.name+"的超级代理权限吗？通用后该超级代理名下的用户系统将不能使用！"
			}
			if(act_type=="start"){
				msg="确定启用"+item.name+"的超级代理权限吗？"
			}
			this.$confirm(msg, {
			    closeOnClickModal: false
			}).then(res => {
			   operateSuperAgentAPi({agent_id:item.id,act_type:act_type}).then(res=>{
				   if(res.code==2000){
					   this.getData()
				   }else{
					   this.$message(res.msg)
				   }
			   })
			}).catch(() => {
			   console.log("cancel")
			})
			
		},
		
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            UsersAgentUsers(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
					
                    this.tableData = res.data.data.data_list
                    
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }

    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
