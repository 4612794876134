<template>
  <div>
    <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
      <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
               label-width="auto">
        <el-form-item label="指定区域：" prop="region_name">
          <div style="display: flex;width: 100%;">
            <el-input v-model.trim="formData.region_name" type="textarea" autosize :disabled="true"
                      :autosize="{ minRows: 2, maxRows: 4 }" style="margin-right: 10px;"></el-input>
            <el-button
                type="primary" @click="handleClickChoiceArea" :disabled="formData.is_default">选择
            </el-button>
          </div>
        </el-form-item>

        <el-form-item label="支持物流：" prop="express_id_list">
          <el-select multiple v-model="formData.express_id_list" placeholder="请选择" style="width: 100%;" filterable
                     clearable @change="expressChange">
            <el-option v-for="item in express_list " :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <template v-for="(item) in formData.rule_list" :key="item.id">
          <el-card style="margin-top: 10px;">
            <el-form-item>
              <el-tag>{{ item.express.name }}</el-tag>
            </el-form-item>
            <el-form-item>
              <el-radio-group v-model="item.cost_type">
                <template v-for="item2 in cost_type" :key="item2.value">
                  <el-radio-button :label="item2.value">{{ item2.text }}</el-radio-button>
                </template>
              </el-radio-group>
            </el-form-item>
            <template v-if="item.cost_type==='express'">
              <el-form-item label="首重（kg）：">
                <el-input-number v-model="item.sz" style="width: 100%;" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item label="运费（元）：">
                <el-input-number v-model="item.yf" style="width: 100%;" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item label="续重（kg）：">
                <el-input-number v-model="item.xz" style="width: 100%;" :controls="false"></el-input-number>
              </el-form-item>
              <el-form-item label="续费（元）：">
                <el-input-number v-model="item.xf" style="width: 100%;" :controls="false"></el-input-number>
              </el-form-item>
            </template>
            <template v-else-if="item.cost_type === 'cold_chain'">
              <el-form-item label="单价(元)：">
                <el-input-number v-model="item.kg_price" style="width: 100%;" :controls="false"></el-input-number>
              </el-form-item>
            </template>
            <template v-else-if="item.cost_type === 'box'">
              <el-form-item label="支持箱子：">
                <el-select multiple v-model="item.express_box" @change="changeBox(item)" placeholder="请选择" style="width: 100%;" filterable
                           clearable>
                  <el-option v-for="item in express_box_list " :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
              <el-table border :data="item.select_express_box" style="width: 100%">
                <el-table-column prop="name" label="规格"></el-table-column>
                <el-table-column label="价格">
                  <template #default="scope">
                    <el-input-number v-model="scope.row.price" style="width: 100%;" :controls="false"></el-input-number>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-card>
        </template>

      </el-form>
      <template #footer>
        <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
        <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
      </template>
    </ly-dialog>
    <lyAreaSelectTree ref="addModuleFlag" @refreshData="handleAreaSelect" v-if="selectDialogVisible"
                      @closed="selectDialogVisible = false">
    </lyAreaSelectTree>
  </div>
</template>

<script>
import {
  addDeliveryApi,
  deliveryEditApi,
  loadDeliverySelectedAreaApi,
  NormalExpressListApi,
  NormalExpressBoxList
} from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import {deepClone} from "@/utils/util"
import lyAreaSelectTree from "@/components/lyAreaSelectTreeV2"

export default {
  components: {LyDialog, lyAreaSelectTree},
  emits: ['refreshData'],
  name: "addModuleFreight",
  data() {
    return {
      dialogVisible: false,
      loadingSave: false,
      loadingTitle: '',
      selectDialogVisible: false,
      cost_type: [
        {value: 'express', text: '快递'},
        {value: 'cold_chain', text: '冷链 (计重计费)'},
        {value: 'box', text: '按箱计件'},
      ],
      formData: {
        region_name: '',
        area: [],
        is_default: false,
        express: [],
        select_express: [],
		rule_list:[],
		express_id_list:[]
      },
      selectedList: [],
      rules: {
        region_name: [
          {required: true, message: '请选择指定区域', trigger: 'blur'}
        ],
      },
      express_list: [],
      express_box_list: [],
    }
  },
  mounted() {
    NormalExpressListApi().then(response => {
      // console.log(response.data)
      this.express_list = response.data
    })
    NormalExpressBoxList().then(response => {
      
      this.express_box_list = response.data
    })
    window.addEventListener("focusin", this.onFocusIn, true);
  },
  unmounted() {
    window.removeEventListener("focusin", this.onFocusIn);
  },
  methods: {
	  expressChange(express_id_list){
		  if(express_id_list && express_id_list.length>0){
			  // if(this.formData.rule_list.length<1){
				  
			  // }
			  express_id_list.forEach(express_id=>{
				 let has_express=this.formData.rule_list.some(rule=>rule.express.id===express_id)
				 if(!has_express){
					this.formData.rule_list.push({
					              express: this.express_list.find(i => i.id === express_id),
					              cost_type: 'express',
					              sz: '',
					              yf: '',
					              xz: '',
					              xf: '',
					              kg_price: '',
					              express_box: [],
					              select_express_box: [],
									rule_list:[],
					            })
				 }
			  })
			let rule_list=this.formData.rule_list.filter((rule)=>{
				return express_id_list.includes(rule.express.id ) 
			})
			this.formData.rule_list=rule_list
			  
			  
			  
			  
			  
		  }else{
			  this.formData.rule_list=[]
		  }
	      // console.log("express:",e)	
		  console.log("rule_list:",this.formData.rule_list)
	  },
    onFocusIn(e) {
      e.stopImmediatePropagation()//阻止当前和后面的一系列事件
    },
    handleClose() {
      this.dialogVisible = false
      this.loadingSave = false
      this.selectDialogVisible = false
      this.formData = {
        region_name: '',
        area: [],
        is_default: false,
        express: [],
        select_express: [],
		rule_list:[],
      }
      this.selectedList = []
    },
    addModuleFn(item, flag) {
      this.loadingTitle = flag
      this.dialogVisible = true
      let id = ""
      if (item) {
        console.log("edit:",item)
        this.formData = deepClone(item)
        id = this.formData.id
		// if(this.formData.express_id_list){
		// 	this.formData.express_id_list.forEach(id=>{
		// 		console.log("id:",id)
		// 		for(let i=0;i<this.express_list.length;i++){
		// 			if(id==this.express_list[i].id){
		// 				this.formData.express.push(this.express_list[i])
		// 			}
		// 		}
		// 	})
		// }
      }
      this.getfmallFreightcSelected(id)
    },
    getfmallFreightcSelected(id) {
      loadDeliverySelectedAreaApi({id: id}).then(res => {
        if (res.code === 2000) {
          this.selectedList = res.data
        }
      })
    },
    handleAreaSelect(selectIdArr, selectNames) {
      this.formData.area = selectIdArr
      // console.log(selectIdArr)
      this.formData.region_name = selectNames
    },
    handleClickChoiceArea() {
      this.selectDialogVisible = true
      this.$nextTick(() => {
        this.$refs.addModuleFlag.handleOpen(this.formData.area, this.formData.region_name, this.selectedList, "选择区域")
      })
    },
    submitData() {
      this.$refs['rulesForm'].validate(obj => {
        if (obj) {
          this.loadingSave = true
          let param = {
            ...this.formData
          }
          if (param.min === "" || param.max === "") {
            this.$message.warning("请输入入位范围")
            return
          }
          if (this.formData.id) {
            deliveryEditApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          } else {
            addDeliveryApi(param).then(res => {
              this.loadingSave = false
              if (res.code == 2000) {
                this.$message.success(res.msg)
                this.handleClose()
                this.$emit('refreshData')
              } else {
                this.$message.warning(res.msg)
              }
            })
          }

        }
      })
    },
    changeBox(item) {
      console.log(item)
      const express_box = item.express_box
      const select_express_box = item.select_express_box

      express_box.forEach(item => {
        if (!select_express_box.some(i => i.id === item)) {
          select_express_box.push({
            id: item,
            name: this.express_box_list.find(i => i.id === item).name,
            price: ''
          })
        }
      })

      select_express_box.forEach(item => {
        if (!express_box.some(i => i === item.id)) {
          select_express_box.splice(select_express_box.findIndex(i => i.id === item.id), 1)
        }
      })

    },
  },
  // watch: {
  //   'formData.express': function (value, old_vlue) {
  //     if (value) {
  //       value.forEach(item => {
  //         if (!old_vlue.includes(item)) {
  //           !this.formData.rule_list && (this.formData.rule_list = [])
  //           if (!this.formData.rule_list.some(i => i.express.id === item)) {
  //             this.formData.rule_list.push({
  //               express: this.express_list.find(i => i.id === item),
  //               cost_type: 'express',
  //               sz: '',
  //               yf: '',
  //               xz: '',
  //               xf: '',
  //               kg_price: '',
  //               express_box: [],
  //               select_express_box: [],
		// 		rule_list:[],
  //             })
  //           }
  //         }
  //       })
		// console.log("old_value:",old_vlue)
		// if(old_vlue){
		// 	old_vlue.forEach(item => {
		// 	  if (!value.includes(item)) {
		// 	    // this.formData.select_express = this.formData.select_express.filter(i => i.id !== item)
		// 	    this.formData.rule_list = this.formData.rule_list.filter(i => i.express.id !== item)
		// 	  }
		// 	})
		// }
     
  //     }
  //   }
  // }
}
</script>

<style>
.set-specs .el-form-item__content {
  background: #e6e6e6 !important;
}
</style>

