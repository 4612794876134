import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

// export const login = (params) => ajaxPost({ url: `token/`, params });
export const loadKeFuListApi = (params={}) =>ajaxGet({url:'ke-fu/list', params})
export const addKeFuApi = (params={}) =>ajaxPost({url:'ke-fu/add', params})
export const editKeFuApi = (params={}) =>ajaxPost({url:'ke-fu/edit', params})
export const manageStatusKeFuApi = (params={}) =>ajaxPost({url:'ke-fu/manage/status', params})
// export const newBannerApi = (params={}) =>ajaxPost({url:'banner/new', params})
// export const manageBannerStatusApi = (params={}) =>ajaxPost({url:'banner/manage/status', params})

// export const stationsEditApi = (params={}) =>ajaxPost({url:'mall/stations/edit/', params})
// export const stationsAddApi = (params={}) =>ajaxPost({url:'mall/stations/add/', params})
// export const manageShopStatusApi = (params={}) =>ajaxPost({url:'shop/manage/status/', params})
// export const auditShopStatusApi = (params={}) =>ajaxPost({url:'shop/audit/', params})