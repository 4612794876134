import axios from "axios";
import {
  reqExpost,
  ajaxGet,
  ajaxPost,
  ajaxDelete,
  ajaxPut,
  ajaxPatch,
  uploadImg,
  ajaxGetDetailByID,
} from "./request";
import { url } from "./url";

export const loadExpressListApi = (params) => ajaxGet({ url: `express/list/`, params });

export const ExpressAddApi = (params) => ajaxPost({ url: `express/add/`, params });

export const ExpressEditApi = (params) => ajaxPost({ url: `express/edit/`, params });

export const manageExpressStatusApi = (params) => ajaxPost({ url: `express/manage/status`, params });

export const newDeliveryRuleFeeApi = (params) => ajaxPost({ url: `mall/orders/new/delivery-fee`, params });

export const exprotOrderExpressApi = (params) => ajaxGet({ url: `mall/export/orders-express/info`, params });